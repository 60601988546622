@media (max-width: 768px) {
    .work1 {
        height: 50vh !important;
        width: 90vw;
        overflow: hidden !important;
        display: grid;
        justify-content: center;
    }
    .work1 > img {
        width: 90vw;
        transition: all, 1s;
        opacity: 0.9;
    }
    .work1 > img:hover {
        width: 100vw;
        opacity: 1;
    }
    .work2 {
        height: 50vh !important;
        width: 90vw;
        overflow: hidden !important;
        display: grid;
        justify-content: center;
    }
    .work2 > img {
        width: 90vw;
        transition: all, 1s;
        opacity: 0.9;
    }
    .work2 > img:hover {
        width: 100vw;
        opacity: 1;
    }
    .work3 {
        height: 50vh !important;
        width: 90vw;
        overflow: hidden !important;
        display: grid;
        justify-content: center;
    }
    .work3 > img {
        width: 90vw;
        transition: all, 1s;
        opacity: 0.9;
    }
    .work3 > img:hover {
        width: 100vw;
        opacity: 1;
    }
    .work4 {
        height: 50vh !important;
        width: 90vw;
        overflow: hidden !important;
        display: grid;
        justify-content: center;
    }
    .work4 > img {
        width: 90vw;
        transition: all, 1s;
        opacity: 0.9;
    }
    .work4 > img:hover {
        width: 100vw;
        opacity: 1;
    }
    .work5 {
        height: 50vh !important;
        width: 90vw;
        overflow: hidden !important;
        display: grid;
        justify-content: center;
    }
    .work5 > img {
        width: 90vw;
        transition: all, 1s;
        opacity: 0.9;
    }
    .work5 > img:hover {
        width: 100vw;
        opacity: 1;
    }
    .casesBlock {
        display: grid;
        grid-row-gap: 2vh;
        padding: 1vw;
        justify-content: center;
    }
    .buttonBlock {
        height: 100vh;
        display: grid;
        justify-content: center;
        align-content: center;
    }
    .first, .second {
        display: grid;
        grid-row-gap: 3vh;
    }
    div:first-child {
        transition: all, 1s;
    }
    .mainTech {
        display: grid;
        justify-content: center;
    }
    .mainTech > div {
        background: #212121;
        padding: 3vh;
        width: 80%;
        margin: auto;
        border-radius: 5vh;
        transition: all, 0.5s;
    }
    .mainTech > div:hover {
        box-shadow: #fc7c33 0 0 10px;
    }
    .head {
        font-size: 4vh;
        color: #fc7c33;
        opacity: 0.7;
        border-bottom: 2px solid #5d5d5d;
        padding-bottom: 2vh;
    }
    .list {
        color: #5d5d5d;
        font-size: 2.5vh;
    }
    .mainTech {
        display: grid;
        padding: 5vw;
        grid-row-gap: 3vh;
    }
    .mainTech > div {
        background: #212121;
        display: grid;
        padding: 3vh;
        border-radius: 5vh;
        transition: all, 0.5s;
    }
    .mainTech > div:hover {
        box-shadow: #fc7c33 0 0 10px;
    }
    .mainCon {
        display: grid;
        background: #212121;
        justify-content: center;
        padding-top: 5vh;
        padding-bottom: 5vh;
    }
    .container {
        display: grid;
        justify-content: center;
        width: 90vw;
        border-radius: 5vh;
        padding: 3vw;
        background: #5d5d5d;
        text-decoration: none;
    }
    .name {
        color: #4f2981;
        font-size: 6vh;
        padding: 4vw;
    }
    .portBlock {
        display: grid;
        grid-row-gap: 2vh;
        background: #212121;
        color: #5d5d5d;
        width: 70%;
        margin: auto;
        padding: 3vh;
        border-radius: 5vh;
        transition: all, 1s;
    }
    .portBlock:hover, .otzBlock:hover {
        box-shadow: #fc7c33 0 0 10px;
    }
    .otzContainer, .portContainer {
        display: grid;
        justify-content: center;
        grid-row-gap: 3vh;
        padding: 3vw;
    }
    .portName, .otzName {
        font-size: 4vh;
        color: #fc7c33;
        opacity: 0.8;
        text-decoration: none;
    }
    .portBlock > img {
        width: 80%;
        border-radius: 5vh;
        margin: auto;
    }
    .someTech {
        padding: 1vh 5vh;
        font-size: 3vh;
        color: #212121;
    }
    .someTech > div {
        font-size: 4vh;
    }
    a {
        text-decoration: none;
    }
}
@media (min-width: 769px) and (max-width: 1024px) {
    .work1 {
        height: 50vh !important;
        width: 65vw;
        overflow: hidden !important;
        display: grid;
        justify-content: center;
    }
    .work1 > img {
        width: 65vw;
        transition: all, 1s;
        opacity: 0.7;
    }
    .work1 > img:hover {
        width: 70vw;
        opacity: 1;
    }
    .work2 {
        height: 50vh !important;
        width: 25vw;
        overflow: hidden !important;
        display: grid;
        justify-content: center;
    }
    .work2 > img {
        width: 25vw;
        transition: all, 1s;
        opacity: 0.7;
    }
    .work2 > img:hover {
        width: 30vw;
        opacity: 1;
    }
    .work3 {
        height: 90vh !important;
        width: 91vw;
        overflow: hidden !important;
        display: grid;
        justify-content: center;
    }
    .work3 > img {
        width: 91vw;
        margin-top: -20vh;
        transition: all, 1s;
        opacity: 0.7;
    }
    .work3 > img:hover {
        width: 95vw;
        opacity: 1;
    }
    .work4 {
        height: 50vh !important;
        width: 35vw;
        overflow: hidden !important;
        display: grid;
        justify-content: center;
    }
    .work4 > img {
        width: 35vw;
        transition: all, 1s;
        opacity: 0.7;
    }
    .work4 > img:hover {
        width: 40vw;
        opacity: 1;
    }
    .work5 {
        height: 50vh !important;
        width: 55vw;
        overflow: hidden !important;
        display: grid;
        justify-content: center;
    }
    .work5 > img {
        width: 55vw;
        transition: all, 1s;
        opacity: 0.7;
    }
    .work5 > img:hover {
        width: 60vw;
        opacity: 1;
    }
    .casesBlock {
        display: grid;
        grid-template-rows: 50vh 90vh 50vh;
        grid-row-gap: 2vh;
        padding: 1vw;
        justify-content: center;
    }
    .first, .second {
        display: grid;
    }
    .first {
        grid-template-columns: 65vw 25vw;
        grid-column-gap: 1vw;
    }
    .second {
        grid-template-columns: 35vw 55vw;
        grid-column-gap: 1vw;
    }
    div:first-child {
        transition: all, 1s;
    }
    .mainTech > div {
        background: #212121;
        width: 22vw;
        padding: 3vh;
        border-radius: 5vh;
        transition: all, 0.5s;
    }
    .mainTech > div:hover {
        box-shadow: #fc7c33 0 0 10px;
    }
    .head {
        font-size: 4vh;
        color: #fc7c33;
        opacity: 0.7;
        border-bottom: 2px solid #5d5d5d;
        padding-bottom: 2vh;
    }
    .list {
        color: #5d5d5d;
        font-size: 2.5vh;
    }
    .mainTech {
        display: grid;
        width: 90vw;
        margin: auto;
        grid-template-columns: 1fr 1fr 1fr;
        grid-column-gap: 6vw;
        justify-content: center;
    }
    .mainTech > div {
        background: #212121;
        padding: 3vh;
        border-radius: 5vh;
        transition: all, 0.5s;
    }
    .mainTech > div:hover {
        box-shadow: #fc7c33 0 0 10px;
    }
    .mainCon {
        display: grid;
        height: auto;
        padding: 5vh 0 25vh 0;
        background: #212121;
    }
    .container {
        display: grid;
        justify-content: center;
        margin: auto;
        width: 90vw;
        padding: 3vh;
        border-radius: 5vh;
        background: #5d5d5d;
        text-decoration: none;
    }
    .name {
        color: #4f2981;
        font-size: 7vh;
        margin-bottom: 3vh;
    }
    .portBlock {
        display: grid;
        background: #212121;
        color: #5d5d5d;
        padding: 3vh;
        border-radius: 5vh;
        grid-template-rows: 1fr 6fr;
        transition: all, 1s;
    }
    .portBlock:hover, .otzBlock:hover {
        box-shadow: #fc7c33 0 0 10px;
    }
    .otzContainer, .portContainer {
        display: grid;
        justify-content: center;
        grid-template-columns: repeat(3, 1fr);
        grid-row-gap: 2vh;
        grid-column-gap: 1vw;
    }
    .portName, .otzName {
        font-size: 4vh;
        color: #fc7c33;
        opacity: 0.8;
        text-decoration: none;
    }
    .portBlock > img {
        width: 80%;
        margin: auto;
    }
    .someTech {
        padding: 1vh;
        font-size: 3vh;
        color: #212121;
    }
    .someTech > div {
        font-size: 4vh;
    }
    a {
        text-decoration: none;
    }
}
@media (min-width: 1025px) {
    .work1 {
        height: 50vh !important;
        width: 65vw;
        overflow: hidden !important;
        display: grid;
        justify-content: center;
    }
    .work1 > img {
        width: 65vw;
        transition: all, 1s;
        opacity: 0.7;
    }
    .work1 > img:hover {
        width: 70vw;
        opacity: 1;
    }
    .work2 {
        height: 50vh !important;
        width: 25vw;
        overflow: hidden !important;
        display: grid;
        justify-content: center;
    }
    .work2 > img {
        width: 25vw;
        transition: all, 1s;
        opacity: 0.7;
    }
    .work2 > img:hover {
        width: 30vw;
        opacity: 1;
    }
    .work3 {
        height: 90vh !important;
        width: 91vw;
        overflow: hidden !important;
        display: grid;
        justify-content: center;
    }
    .work3 > img {
        width: 91vw;
        margin-top: -20vh;
        transition: all, 1s;
        opacity: 0.7;
    }
    .work3 > img:hover {
        width: 95vw;
        opacity: 1;
    }
    .work4 {
        height: 50vh !important;
        width: 35vw;
        overflow: hidden !important;
        display: grid;
        justify-content: center;
    }
    .work4 > img {
        width: 35vw;
        transition: all, 1s;
        opacity: 0.7;
    }
    .work4 > img:hover {
        width: 40vw;
        opacity: 1;
    }
    .work5 {
        height: 50vh !important;
        width: 55vw;
        overflow: hidden !important;
        display: grid;
        justify-content: center;
    }
    .work5 > img {
        width: 55vw;
        transition: all, 1s;
        opacity: 0.7;
    }
    .work5 > img:hover {
        width: 60vw;
        opacity: 1;
    }
    .casesBlock {
        display: grid;
        grid-template-rows: 50vh 90vh 50vh;
        grid-row-gap: 2vh;
        padding: 1vw;
        justify-content: center;
    }
    .first, .second {
        display: grid;
    }
    .first {
        grid-template-columns: 65vw 25vw;
        grid-column-gap: 1vw;
    }
    .second {
        grid-template-columns: 35vw 55vw;
        grid-column-gap: 1vw;
    }
    div:first-child {
        transition: all, 1s;
    }
    .mainTech {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        justify-content: space-around;
    }
    .head {
        font-size: 4vh;
        color: #fc7c33;
        opacity: 0.7;
        border-bottom: 2px solid #5d5d5d;
        padding-bottom: 2vh;
    }
    .list {
        color: #5d5d5d;
        font-size: 2.5vh;
    }
    .mainTech > div {
        background: #212121;
        width: 22vw;
        height: 40vh;
        margin: auto;
        padding: 3vh;
        border-radius: 5vh;
        transition: all, 0.5s;
    }
    .mainTech > div:hover {
        box-shadow: #fc7c33 0 0 10px;
    }
    .mainCon {
        display: grid;
        padding: 5vh 0;
        justify-content: center;
        background: #212121;
    }
    .container {
        display: grid;
        width: 90vw;
        padding: 2vw;
        border-radius: 5vh;
        background: #5d5d5d;
        text-decoration: none;
    }
    .name {
        color: #4f2981;
        font-size: 7vh;
        margin-bottom: 3vh;
    }
    .portBlock {
        display: grid;
        background: #212121;
        color: #5d5d5d;
        padding: 3vh;
        width: 13vw;
        border-radius: 5vh;
        margin-left: auto;
        margin-right: auto;
        grid-template-rows: 1fr 3fr;
        transition: all, 1s;
    }
    .portBlock:hover, .otzBlock:hover {
        box-shadow: #fc7c33 0 0 10px;
    }
    .otzContainer, .portContainer {
        display: grid;
        grid-template-columns: repeat(5, 1fr);
        justify-content: space-around;
    }
    .portName, .otzName {
        font-size: 4vh;
        color: #fc7c33;
        opacity: 0.8;
        text-decoration: none;
    }
    .portBlock > img {
        width: 80%;
        margin: auto;
    }
    .someTech {
        padding: 1vh;
        font-size: 3vh;
        color: #212121;
    }
    .someTech > div {
        font-size: 4vh;
    }
    a {
        text-decoration: none;
    }
}
