@media (max-width: 768px) {

}

@media (min-width: 769px) and (max-width: 1024px) {

}

@media (min-width: 1025px) {

    .cursor-dot,
    .cursor-circle,
    .cursor {
        mix-blend-mode: normal;
        pointer-events: none;
        position: fixed;
        top: 0;
        left: 0;
        border-radius: 50%;
        opacity: 1;
        transform: translate(-50%, -50%);
    }

    .cursor-dot {
        z-index: 9999;
        width: 36px;
        height: 36px;
        display: grid;
        justify-content: center;
        align-items: center;
    }

    .cursor-circle {
        z-index: 9998;
        width: 36px;
        height: 36px;
        background-color: rgba(252, 124, 35, 0.8);
        box-shadow: #fc7c33 0 0 20px;
    }
    .cursor {
        z-index: 9999;
        width: 11px;
        height: 11px;
        background-color: #4f2981;
        box-shadow: #702ACF 0 0 5px;
    }
}

