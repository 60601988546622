@media (max-width: 768px) {
    .container {
        background: #212121;
        display: grid;
        justify-content: center;
        align-content: center;
        height: 70vh;
        padding: 3vh;
    }
    .container > button {
        font-size: 6vh;
        width: 100%;
        height: 70vh;
        border: 2px solid #702ACF;
        background: #212121;
        color: #702ACF;
        margin: auto;
        border-radius: 10vh;
        padding: 2vh;
        font-weight: bold;
        /*opacity: 0.49;*/
        box-shadow: #702ACF 0 0 10px;
        cursor: pointer;
        transition: all, 0.5s;
    }
    .container > button:hover {
        background: #702ACF;
        color: #212121;
    }
    .link {
        display: grid;
        justify-content: center;
    }
    .name {
        font-size: 4vh;
        color: #212121;
        padding-bottom: 4vh;
    }
    .modal {
        display: block;
        margin: 11vh auto 20px auto;
        background: #212121;
        width: 95vw;
        height: 75vh;
        border-radius: 5vh;
        overflow: scroll;
    }
    .containerCalc {
        padding: 3vh;
    }
    .nameModal {
        font-size: 3.8vh;
        color: #702ACF;
        display: grid;
    }
    /* для элемента input c type="checkbox" */
    .customCheckbox>input {
        position: absolute;
        z-index: -1;
        opacity: 0;
    }

    /* для элемента label, связанного с .custom-checkbox */
    .customCheckbox>span {
        display: inline-flex;
        align-items: center;
        user-select: none;
        font-size: 3vh;
    }

    /* создание в label псевдоэлемента before со следующими стилями */
    .customCheckbox>span::before {
        content: '';
        display: inline-block;
        width: 1em;
        height: 1em;
        flex-shrink: 0;
        flex-grow: 0;
        border: 1px solid #fc7c33;
        border-radius: 0.25em;
        margin-right: 0.5em;
        background-repeat: no-repeat;
        background-position: center center;
        background-size: 50% 50%;
    }

    /* стили при наведении курсора на checkbox */
    .customCheckbox>input:not(:disabled):not(:checked)+span:hover::before {
        border-color: #fc7c33;
    }

    /* стили для активного чекбокса (при нажатии на него) */
    .customCheckbox>input:not(:disabled):active+span::before {
        background-color: #fc7c33;
        border-color: #fc7c33;
    }

    /* стили для чекбокса, находящегося в фокусе */
    .customCheckbox>input:focus+span::before {
        box-shadow: 0 0 0 0.2rem rgba(252, 124, 51, 0.25);
    }

    /* стили для чекбокса, находящегося в фокусе и не находящегося в состоянии checked */
    .customCheckbox>input:focus:not(:checked)+span::before {
        border-color: #fc7c33;
    }

    /* стили для чекбокса, находящегося в состоянии checked */
    .customCheckbox>input:checked+span::before {
        border-color: #fc7c33;
        background-color: #fc7c33;
        background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3e%3c/svg%3e");
    }
    /* для элемента input c type="radio" */
    .customRadio>input {
        position: absolute;
        z-index: -1;
        opacity: 0;
    }

    /* для элемента label связанного с .custom-radio */
    .customRadio>span {
        display: inline-flex;
        align-items: center;
        user-select: none;
        font-size: 3vh;
    }

    /* создание в label псевдоэлемента  before со следующими стилями */
    .customRadio>span::before {
        content: '';
        display: inline-block;
        width: 1em;
        height: 1em;
        flex-shrink: 0;
        flex-grow: 0;
        border: 1px solid #fc7c33;
        border-radius: 50%;
        margin-right: 0.5em;
        background-repeat: no-repeat;
        background-position: center center;
        background-size: 50% 50%;
    }

    /* стили при наведении курсора на радио */
    .customRadio>input:not(:disabled):not(:checked)+span:hover::before {
        border-color: #fc7c33;
    }

    /* стили для активной радиокнопки (при нажатии на неё) */
    .customRadio>input:not(:disabled):active+span::before {
        background-color: #fc7c33;
        border-color: #fc7c33;
    }

    /* стили для радиокнопки, находящейся в фокусе */
    .customRadio>input:focus+span::before {
        box-shadow: 0 0 0 0.2rem rgba(252, 124, 51, 0.25);
    }

    /* стили для радиокнопки, находящейся в фокусе и не находящейся в состоянии checked */
    .customRadio>input:focus:not(:checked)+span::before {
        border-color: #fc7c33;
    }

    /* стили для радиокнопки, находящейся в состоянии checked */
    .customRadio>input:checked+span::before {
        border-color: #fc7c33;
        background-color: #fc7c33;
        background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
    }

    .customRadio1>input {
        position: absolute;
        z-index: -1;
        opacity: 0;
    }

    /* для элемента label связанного с .custom-radio */
    .customRadio1>span {
        display: inline-flex;
        align-items: center;
        user-select: none;
        font-size: 3vh;
        white-space: nowrap;
    }

    /* создание в label псевдоэлемента  before со следующими стилями */
    .customRadio1>span::before {
        content: '';
        display: inline-block;
        width: 1em;
        height: 1em;
        flex-shrink: 0;
        flex-grow: 0;
        border: 1px solid #fc7c33;
        border-radius: 50%;
        margin-right: 0.5em;
        background-repeat: no-repeat;
        background-position: center center;
        background-size: 50% 50%;
    }

    /* стили при наведении курсора на радио */
    .customRadio1>input:not(:disabled):not(:checked)+span:hover::before {
        border-color: #fc7c33;
    }

    /* стили для активной радиокнопки (при нажатии на неё) */
    .customRadio1>input:not(:disabled):active+span::before {
        background-color: #fc7c33;
        border-color: #fc7c33;
    }

    /* стили для радиокнопки, находящейся в фокусе */
    .customRadio1>input:focus+span::before {
        box-shadow: 0 0 0 0.2rem rgba(252, 124, 51, 0.25);
    }

    /* стили для радиокнопки, находящейся в фокусе и не находящейся в состоянии checked */
    .customRadio1>input:focus:not(:checked)+span::before {
        border-color: #fc7c33;
    }

    /* стили для радиокнопки, находящейся в состоянии checked */
    .customRadio1>input:checked+span::before {
        border-color: #fc7c33;
        background-color: #fc7c33;
        background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
    }

    /* стили для радиокнопки, находящейся в состоянии disabled */
    .customRadio1>input:disabled+span::before {
        background-color: #fc7c33;
    }
    .customCheckbox, .customRadio {
        color: #fc7c33;
        margin-right: 5vh;
        font-size: 3.4vh;
    }

    input[type=range]  {
        width: 200px;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        border: 3px solid #5d5d5d;
        height: 1vh;
        border-radius: 1vh;
        margin: auto auto auto 0;
    }

    input[type=range]::-webkit-slider-runnable-track {
        height: 1vh;
        background-color: #5d5d5d;
        border-radius: 1vh;
    }

    input[type=range]::-webkit-slider-thumb {
        background: #fc7c33;
        cursor: pointer;
        width:3vh;
        height: 3vh;
        -webkit-appearance: none;
        border-radius: 5vh;
        margin-top: -1vh;
    }

    input[type=range]::-moz-range-track {
        height: 2vh;
        background-color: #fc7c33;
    }


    input[type=range]::-moz-range-thumb {
        background: #fc7c33;
        cursor: pointer;
        height: 7px;
        border-radius:1vh;
        padding: 0px;
        margin: 0px;
    }
    .numValue {
        color: #5d5d5d;
        font-size: 5vh;
    }
    .numContainer {
        display: grid;
        grid-template-columns: 1fr 5fr;
    }
    .newContainer {
        display: grid;
    }
    .newContainer > button {
        font-size: 3vh;
        width: 100%;
        border: 2px solid #702ACF;
        background: #212121;
        color: #702ACF;
        margin: auto;
        border-radius: 10vh;
        padding: 1vh;
        font-weight: bold;
        /*opacity: 0.49;*/
        box-shadow: #702ACF 0 0 10px;
        cursor: pointer;
        transition: all, 0.5s;
        order: 1;
    }
    .newContainer > button:hover {
        background: #702ACF;
        color: #212121;
    }
    .nameModal2 {
        font-size: 5vh;
        color: #5d5d5d;
        order: 2;
    }
    .closer {
        display: grid;
    }
    .customRadio1 {
        width: 14vw;
        font-size: 3.5vh;
        color: #fc7c33;
    }

    .cross {
        font-size: 6vh;
        display: grid;
        justify-content: end;
        align-items: center;
        margin-top: -39vh;
        margin-left: 18vw;
        color: #5d5d5d;
    }
    .real {
        cursor: pointer;
    }
}
@media (min-width: 769px) and (max-width: 1024px) {
    .container {
        background: #212121;
        display: grid;
        justify-content: center;
        padding: 10vh 5vh 5vh 5vh;
    }
    .link > button {
        font-size: 3vh;
        width: 100%;
        border: 2px solid #702ACF;
        background: #212121;
        color: #702ACF;
        margin: auto;
        border-radius: 10vh;
        padding: 2vh;
        font-weight: bold;
        /*opacity: 0.49;*/
        box-shadow: #702ACF 0 0 10px;
        cursor: pointer;
        transition: all, 0.5s;
    }
    .link > button:hover {
        background: #702ACF;
        color: #212121;
    }
    .container > div {
        background: #5d5d5d;
        padding: 5vh;
        border-radius: 5vh;
        display: grid;
        transition: all, 0.5s;
    }
    .link {
        display: grid;
        justify-content: center;
    }
    .name {
        font-size: 4vh;
        color: #212121;
        padding-bottom: 4vh;
    }
    .modal {
        display: block;
        margin: 11vh auto auto auto;
        background: #212121;
        width: 95vw;
        height: 95vh;
        border-radius: 5vh;
    }
    .containerCalc {
        padding: 3vh;
    }
    .nameModal {
        font-size: 3.8vh;
        color: #702ACF;
        display: grid;
    }
    /* для элемента input c type="checkbox" */
    .customCheckbox>input {
        position: absolute;
        z-index: -1;
        opacity: 0;
    }

    /* для элемента label, связанного с .custom-checkbox */
    .customCheckbox>span {
        display: inline-flex;
        align-items: center;
        user-select: none;
        font-size: 3vh;
    }

    /* создание в label псевдоэлемента before со следующими стилями */
    .customCheckbox>span::before {
        content: '';
        display: inline-block;
        width: 1em;
        height: 1em;
        flex-shrink: 0;
        flex-grow: 0;
        border: 1px solid #fc7c33;
        border-radius: 0.25em;
        margin-right: 0.5em;
        background-repeat: no-repeat;
        background-position: center center;
        background-size: 50% 50%;
    }

    /* стили при наведении курсора на checkbox */
    .customCheckbox>input:not(:disabled):not(:checked)+span:hover::before {
        border-color: #fc7c33;
    }

    /* стили для активного чекбокса (при нажатии на него) */
    .customCheckbox>input:not(:disabled):active+span::before {
        background-color: #fc7c33;
        border-color: #fc7c33;
    }

    /* стили для чекбокса, находящегося в фокусе */
    .customCheckbox>input:focus+span::before {
        box-shadow: 0 0 0 0.2rem rgba(252, 124, 51, 0.25);
    }

    /* стили для чекбокса, находящегося в фокусе и не находящегося в состоянии checked */
    .customCheckbox>input:focus:not(:checked)+span::before {
        border-color: #fc7c33;
    }

    /* стили для чекбокса, находящегося в состоянии checked */
    .customCheckbox>input:checked+span::before {
        border-color: #fc7c33;
        background-color: #fc7c33;
        background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3e%3c/svg%3e");
    }
    /* для элемента input c type="radio" */
    .customRadio>input {
        position: absolute;
        z-index: -1;
        opacity: 0;
    }

    /* для элемента label связанного с .custom-radio */
    .customRadio>span {
        display: inline-flex;
        align-items: center;
        user-select: none;
        font-size: 3vh;
    }

    /* создание в label псевдоэлемента  before со следующими стилями */
    .customRadio>span::before {
        content: '';
        display: inline-block;
        width: 1em;
        height: 1em;
        flex-shrink: 0;
        flex-grow: 0;
        border: 1px solid #fc7c33;
        border-radius: 50%;
        margin-right: 0.5em;
        background-repeat: no-repeat;
        background-position: center center;
        background-size: 50% 50%;
    }

    /* стили при наведении курсора на радио */
    .customRadio>input:not(:disabled):not(:checked)+span:hover::before {
        border-color: #fc7c33;
    }

    /* стили для активной радиокнопки (при нажатии на неё) */
    .customRadio>input:not(:disabled):active+span::before {
        background-color: #fc7c33;
        border-color: #fc7c33;
    }

    /* стили для радиокнопки, находящейся в фокусе */
    .customRadio>input:focus+span::before {
        box-shadow: 0 0 0 0.2rem rgba(252, 124, 51, 0.25);
    }

    /* стили для радиокнопки, находящейся в фокусе и не находящейся в состоянии checked */
    .customRadio>input:focus:not(:checked)+span::before {
        border-color: #fc7c33;
    }

    /* стили для радиокнопки, находящейся в состоянии checked */
    .customRadio>input:checked+span::before {
        border-color: #fc7c33;
        background-color: #fc7c33;
        background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
    }

    .customRadio1>input {
        position: absolute;
        z-index: -1;
        opacity: 0;
    }

    /* для элемента label связанного с .custom-radio */
    .customRadio1>span {
        display: inline-flex;
        align-items: center;
        user-select: none;
        font-size: 3vh;
        white-space: nowrap;
    }

    /* создание в label псевдоэлемента  before со следующими стилями */
    .customRadio1>span::before {
        content: '';
        display: inline-block;
        width: 1em;
        height: 1em;
        flex-shrink: 0;
        flex-grow: 0;
        border: 1px solid #fc7c33;
        border-radius: 50%;
        margin-right: 0.5em;
        background-repeat: no-repeat;
        background-position: center center;
        background-size: 50% 50%;
    }

    /* стили при наведении курсора на радио */
    .customRadio1>input:not(:disabled):not(:checked)+span:hover::before {
        border-color: #fc7c33;
    }

    /* стили для активной радиокнопки (при нажатии на неё) */
    .customRadio1>input:not(:disabled):active+span::before {
        background-color: #fc7c33;
        border-color: #fc7c33;
    }

    /* стили для радиокнопки, находящейся в фокусе */
    .customRadio1>input:focus+span::before {
        box-shadow: 0 0 0 0.2rem rgba(252, 124, 51, 0.25);
    }

    /* стили для радиокнопки, находящейся в фокусе и не находящейся в состоянии checked */
    .customRadio1>input:focus:not(:checked)+span::before {
        border-color: #fc7c33;
    }

    /* стили для радиокнопки, находящейся в состоянии checked */
    .customRadio1>input:checked+span::before {
        border-color: #fc7c33;
        background-color: #fc7c33;
        background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
    }

    /* стили для радиокнопки, находящейся в состоянии disabled */
    .customRadio1>input:disabled+span::before {
        background-color: #fc7c33;
    }
    .customCheckbox, .customRadio {
        color: #fc7c33;
        margin-right: 5vh;
        font-size: 3.4vh;
    }

    input[type=range]  {
        width: 200px;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        border: 3px solid #5d5d5d;
        height: 1vh;
        border-radius: 1vh;
        margin: auto auto auto 0;
    }

    input[type=range]::-webkit-slider-runnable-track {
        height: 1vh;
        background-color: #5d5d5d;
        border-radius: 1vh;
    }

    input[type=range]::-webkit-slider-thumb {
        background: #fc7c33;
        cursor: pointer;
        width:3vh;
        height: 3vh;
        -webkit-appearance: none;
        border-radius: 5vh;
        margin-top: -1vh;
    }

    input[type=range]::-moz-range-track {
        height: 2vh;
        background-color: #fc7c33;
    }


    input[type=range]::-moz-range-thumb {
        background: #fc7c33;
        cursor: pointer;
        height: 7px;
        border-radius:1vh;
        padding: 0px;
        margin: 0px;
    }
    .numValue {
        color: #5d5d5d;
        font-size: 5vh;
    }
    .numContainer {
        display: grid;
        grid-template-columns: 1fr 5fr;
    }
    .newContainer {
        display: grid;
    }
    .newContainer > button {
        font-size: 3vh;
        width: 40vw;
        border: 2px solid #702ACF;
        background: #212121;
        color: #702ACF;
        margin: auto;
        border-radius: 10vh;
        padding: 1vh;
        font-weight: bold;
        /*opacity: 0.49;*/
        box-shadow: #702ACF 0 0 10px;
        cursor: pointer;
        transition: all, 0.5s;
        order: 1;
    }
    .newContainer > button:hover {
        background: #702ACF;
        color: #212121;
    }
    .nameModal2 {
        font-size: 5vh;
        color: #5d5d5d;
        order: 2;
    }
    .closer {
        display: grid;
    }
    .customRadio1 {
        width: 14vw;
        font-size: 3.5vh;
        color: #fc7c33;
    }
    .design {
        display: none;
    }
    .cross {
        font-size: 6vh;
        display: grid;
        justify-content: end;
        align-items: center;
        margin-top: -29vh;
        margin-left: 18vw;
        color: #5d5d5d;
    }
    .real {
        cursor: pointer;
    }
}
@media (min-width: 1025px) {
    .container {
        background: #212121;
        display: grid;
        justify-content: center;
        align-content: center;
        height: 100vh;
    }
    .container > button {
        height: 60vh;
        width: 80vw;
        color: #4f2981;
        box-shadow: 0 0 10px #4f2981;
        background: #212121;
        border-radius: 10vh;
        border: 8px solid #4f2981;
        font-size: 5vw;
        font-weight: bolder;
        transition: 1s;
        cursor: pointer;
    }
    .container > button:hover {
        background: #4f2981;
        color: #212121;
    }
    .container > div {
        background: #5d5d5d;
        padding: 5vh;
        border-radius: 5vh;
        display: grid;
        transition: all, 0.5s;
    }
    .link {
        display: grid;
        justify-content: center;
    }
    .name {
        font-size: 5vh;
        color: #212121;
        padding-bottom: 4vh;
    }
    .modal {
        display: block;
        margin: 15vh auto auto auto;
        background: #212121;
        width: 95vw;
        height: 90vh;
        border-radius: 5vh;
        overflow: scroll;
    }
    .containerCalc {
        padding: 5vh;
    }
    .nameModal {
        font-size: 4vh;
        color: #702ACF;
    }
    /* для элемента input c type="checkbox" */
    .customCheckbox>input {
        position: absolute;
        z-index: -1;
        opacity: 0;
    }

    /* для элемента label, связанного с .custom-checkbox */
    .customCheckbox>span {
        display: inline-flex;
        align-items: center;
        user-select: none;
    }

    /* создание в label псевдоэлемента before со следующими стилями */
    .customCheckbox>span::before {
        content: '';
        display: inline-block;
        width: 1em;
        height: 1em;
        flex-shrink: 0;
        flex-grow: 0;
        border: 1px solid #fc7c33;
        border-radius: 0.25em;
        margin-right: 0.5em;
        background-repeat: no-repeat;
        background-position: center center;
        background-size: 50% 50%;
    }

    /* стили при наведении курсора на checkbox */
    .customCheckbox>input:not(:disabled):not(:checked)+span:hover::before {
        border-color: #fc7c33;
    }

    /* стили для активного чекбокса (при нажатии на него) */
    .customCheckbox>input:not(:disabled):active+span::before {
        background-color: #fc7c33;
        border-color: #fc7c33;
    }

    /* стили для чекбокса, находящегося в фокусе */
    .customCheckbox>input:focus+span::before {
        box-shadow: 0 0 0 0.2rem rgba(252, 124, 51, 0.25);
    }

    /* стили для чекбокса, находящегося в фокусе и не находящегося в состоянии checked */
    .customCheckbox>input:focus:not(:checked)+span::before {
        border-color: #fc7c33;
    }

    /* стили для чекбокса, находящегося в состоянии checked */
    .customCheckbox>input:checked+span::before {
        border-color: #fc7c33;
        background-color: #fc7c33;
        background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3e%3c/svg%3e");
    }
    /* для элемента input c type="radio" */
    .customRadio>input {
        position: absolute;
        z-index: -1;
        opacity: 0;
    }

    /* для элемента label связанного с .custom-radio */
    .customRadio>span {
        display: inline-flex;
        align-items: center;
        user-select: none;
        white-space: nowrap !important;
    }

    /* создание в label псевдоэлемента  before со следующими стилями */
    .customRadio>span::before {
        content: '';
        display: inline-block;
        width: 1em;
        height: 1em;
        flex-shrink: 0;
        flex-grow: 0;
        border: 1px solid #fc7c33;
        border-radius: 50%;
        margin-right: 0.5em;
        background-repeat: no-repeat;
        background-position: center center;
        background-size: 50% 50%;

    }

    /* стили при наведении курсора на радио */
    .customRadio>input:not(:disabled):not(:checked)+span:hover::before {
        border-color: #fc7c33;
    }

    /* стили для активной радиокнопки (при нажатии на неё) */
    .customRadio>input:not(:disabled):active+span::before {
        background-color: #fc7c33;
        border-color: #fc7c33;
    }

    /* стили для радиокнопки, находящейся в фокусе */
    .customRadio>input:focus+span::before {
        box-shadow: 0 0 0 0.2rem rgba(252, 124, 51, 0.25);
    }

    /* стили для радиокнопки, находящейся в фокусе и не находящейся в состоянии checked */
    .customRadio>input:focus:not(:checked)+span::before {
        border-color: #fc7c33;
    }

    /* стили для радиокнопки, находящейся в состоянии checked */
    .customRadio>input:checked+span::before {
        border-color: #fc7c33;
        background-color: #fc7c33;
        background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
    }

    .customRadio1>input {
        position: absolute;
        z-index: -1;
        opacity: 0;
    }

    /* для элемента label связанного с .custom-radio */
    .customRadio1>span {
        display: inline-flex;
        align-items: center;
        user-select: none;
    }

    /* создание в label псевдоэлемента  before со следующими стилями */
    .customRadio1>span::before {
        content: '';
        display: inline-block;
        width: 1em;
        height: 1em;
        flex-shrink: 0;
        flex-grow: 0;
        border: 1px solid #fc7c33;
        border-radius: 50%;
        margin-right: 0.5em;
        background-repeat: no-repeat;
        background-position: center center;
        background-size: 50% 50%;
    }

    /* стили при наведении курсора на радио */
    .customRadio1>input:not(:disabled):not(:checked)+span:hover::before {
        border-color: #fc7c33;
    }

    /* стили для активной радиокнопки (при нажатии на неё) */
    .customRadio1>input:not(:disabled):active+span::before {
        background-color: #fc7c33;
        border-color: #fc7c33;
    }

    /* стили для радиокнопки, находящейся в фокусе */
    .customRadio1>input:focus+span::before {
        box-shadow: 0 0 0 0.2rem rgba(252, 124, 51, 0.25);
    }

    /* стили для радиокнопки, находящейся в фокусе и не находящейся в состоянии checked */
    .customRadio1>input:focus:not(:checked)+span::before {
        border-color: #fc7c33;
    }

    /* стили для радиокнопки, находящейся в состоянии checked */
    .customRadio1>input:checked+span::before {
        border-color: #fc7c33;
        background-color: #fc7c33;
        background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
    }

    /* стили для радиокнопки, находящейся в состоянии disabled */
    .customRadio1>input:disabled+span::before {
        background-color: #fc7c33;
    }
    .customCheckbox, .customRadio {
        color: #fc7c33;
        margin-right: 5vh;
        font-size: 3.4vh;
    }

    input[type=range]  {
        width: 200px;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        border: 3px solid #5d5d5d;
        height: 1vh;
        border-radius: 1vh;
        margin: auto auto auto 0;
    }

    input[type=range]::-webkit-slider-runnable-track {
        height: 1vh;
        background-color: #5d5d5d;
        border-radius: 1vh;
    }

    input[type=range]::-webkit-slider-thumb {
        background: #fc7c33;
        cursor: pointer;
        width:3vh;
        height: 3vh;
        -webkit-appearance: none;
        border-radius: 5vh;
        margin-top: -1vh;
    }

    input[type=range]::-moz-range-track {
        height: 2vh;
        background-color: #fc7c33;
    }


    input[type=range]::-moz-range-thumb {
        background: #fc7c33;
        cursor: pointer;
        height: 7px;
        border-radius:1vh;
        padding: 0px;
        margin: 0px;
    }
    .numValue {
        color: #5d5d5d;
        font-size: 5vh;
    }
    .numContainer {
        display: grid;
        grid-template-columns: 1fr 5fr;
    }
    .newContainer {
        display: grid;
        grid-template-columns: 1fr 1fr;
    }
    .newContainer > button {
        font-size: 4vh;
        width: 20vw;
        border: 2px solid #702ACF;
        background: #212121;
        color: #702ACF;
        margin: auto;
        border-radius: 10vh;
        padding: 2vh;
        font-weight: bold;
        /*opacity: 0.49;*/
        box-shadow: #702ACF 0 0 10px;
        cursor: pointer;
        transition: all, 0.5s;
    }
    .newContainer > button:hover {
        background: #702ACF;
        color: #212121;
    }
    .nameModal2 {
        font-size: 6vh;
        color: #5d5d5d;
    }
    .closer {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 6fr;
    }
    .customRadio1 {
        width: 14vw;
        font-size: 3.5vh;
        color: #fc7c33;
    }
    .cross {
        font-size: 6vh;
        display: grid;
        justify-content: end;
        align-items: center;
        margin-top: -14vh;
        color: #5d5d5d;
    }
    .real {
        cursor: pointer;
    }
}
