@media (max-width: 768px) {
    .container {
        display: grid;
        justify-content: center;
    }
    .block {
        display: flex;
        background: #5d5d5d;
        padding: 5vh;
        border-radius: 5vh;
        color: #fc7c33;
        width: 75vw;
        margin-top: 3vh;
        justify-content: center;
        align-items: center;
        font-size: 4vh;
        text-decoration: underline;
        transition: all, 0.5s;
    }
    .block:hover {
        box-shadow: #fc7c33 0 0 15px;
    }
}
@media (min-width: 769px) and (max-width: 1024px) {
    .container {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        padding: 5vh;
    }
    .block {
        display: flex;
        background: #5d5d5d;
        padding: 5vh;
        border-radius: 5vh;
        color: #fc7c33;
        width: 15vw;
        justify-content: center;
        align-items: center;
        margin-left: 6vw;
        font-size: 4vh;
        text-decoration: underline;
        transition: all, 0.5s;
    }
    .block:hover {
        box-shadow: #fc7c33 0 0 15px;
    }
}
@media (min-width: 1025px) {
    .container {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        padding: 5vh;
    }
    .block {
        display: flex;
        background: #5d5d5d;
        padding: 5vh;
        border-radius: 5vh;
        color: #fc7c33;
        width: 15vw;
        justify-content: center;
        align-items: center;
        margin-left: 6vw;
        font-size: 4vh;
        text-decoration: underline;
        transition: all, 0.5s;
    }
    .block:hover {
        box-shadow: #fc7c33 0 0 15px;
    }
}

