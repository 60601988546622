@media (max-width: 768px) {
    .container {
        background: #212121;
        display: grid;
        justify-content: center;
    }
    .left > img {
        height: 60vh;
        transition: all, 0.5s;
    }
    .rightCont {
        display: grid;
        justify-content: center;
        padding: 10vw;
    }
    .container > .rightCont > p {
        display: block;
        background: #5d5d5d;
        color: #212121;
        border-radius: 5vh;
        padding: 5vh;
        font-size: 3.5vh;
        transition: all, 0.5s;
    }
    .container > .rightCont > p > div {
        display: inline-block !important;
        color: #4f2981;
    }
}
@media (min-width: 769px) and (max-width: 1024px) {
    .container {
        background: #212121;
        display: grid;
        grid-template-columns: 1fr 1fr;
        padding: 5vh;
    }
    .left > img {
        height: 90vh;
        transition: all, 0.5s;
    }
    .left > img:hover {
        rotate: 5deg;
    }
    .container > .rightCont > p {
        display: block;
        background: #5d5d5d;
        color: #212121;
        border-radius: 5vh;
        padding: 5vh;
        font-size: 4vh;
        margin-top: 20vh;
        transition: all, 0.5s;
    }
    .container > .rightCont > p:hover {
        box-shadow: #4f2981 0 0 20px;
    }
    .container > .rightCont > p > div {
        display: inline-block !important;
        color: #4f2981;
    }
}
@media (min-width: 1025px) {
    .container {
        background: #212121;
        display: grid;
        grid-template-columns: 1fr 1fr;
        padding: 5vh;
    }
    .left > img {
        height: 90vh;
        margin: auto;
        transition: all, 0.5s;
    }
    .left > img:hover {
        rotate: 5deg;
    }
    .container > .rightCont > p {
        display: block;
        background: #5d5d5d;
        color: #212121;
        border-radius: 5vh;
        padding: 5vh;
        font-size: 5vh;
        margin-top: 20vh;
        transition: all, 0.5s;
    }
    .container > .rightCont > p:hover {
        box-shadow: #4f2981 0 0 20px;
    }
    .container > .rightCont > p > div {
        display: inline-block !important;
        color: #4f2981;
    }
}
