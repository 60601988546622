@media (max-width: 768px) {
    .container {
        display: grid;
        background-position: center;
        justify-content: center;
        padding-top: 10vh;
        height: 140vh;
        margin-top: -140vh;
        overflow: hidden;
    }
    .right {
        padding: 5vh;
        display: grid;
        justify-content: center;
    }
    .right > img {
        height: 60vh;
        transition: all, 1s;
        animation: img 1s ease;
        z-index: 28;
    }
    .right > img:hover {
        rotate: -10deg;
    }
    .left {
        display: grid;
        padding: 5vh;
        transition: all, 0.5s;
        justify-content: center;
        align-items: center;
    }
    .left > h1 {
        display: block;
        color: #4f2981;
        font-weight: bolder;
        font-size: 10vh;
        transition: all, 0.5s;
        z-index: 28;
        animation: name 1s ease;
    }
}
@media (min-width: 769px) and (max-width: 1024px) {
    .container {
        display: grid;
        grid-template-columns: 1fr 1fr;
        height: 100vh;
        margin-top: -100vh;
        overflow: hidden;
    }
    .right {
        display: grid;
        justify-content: center;
        align-items: center;
    }
    .right > img {
        height: 80vh;
        transition: all, 1s;
        animation: img 1s ease;
        z-index: 28;
    }
    .right > img:hover {
        rotate: -10deg;
    }
    .left {
        display: grid;
        transition: all, 0.5s;
        justify-content: center;
        align-items: center;
    }
    .left > h1 {
        display: block;
        color: #4f2981;
        font-weight: bolder;
        font-size: 18vh;
        transition: all, 0.5s;
        animation: name 1s ease;
        z-index: 28;
    }
}
@media (min-width: 1025px) {
    .container {
        display: grid;
        grid-template-columns: 1fr 1fr;
        overflow: hidden;
        height: 140vh;
        margin-top: -120vh;
    }
    .right {
        display: grid;
        justify-content: center;
        align-items: center;
    }
    .right > img {
        height: 80vh;
        transition: all, 1s;
        animation: img 1s ease;
        z-index: 28;
    }
    .left {
        display: grid;
        transition: all, 0.5s;
        justify-content: center;
        text-align: center;
        height: 55vh;
        padding-top: 25vh;
        padding-left: 5vw;
        align-items: center;
    }
    .left > h1 {
        display: block;
        color: #4f2981;
        font-weight: bolder;
        font-size: 18vh;
        margin-top: -40vh;
        transition: all, 0.5s;
        animation: name 1s ease;
        z-index: 28;
    }
    .right > img:hover {
        rotate: -10deg;
    }
}