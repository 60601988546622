@media (max-width: 768px) {
    .container {
        display: grid;
        background-position: center;
        height: 139.5vh;
        margin-top: -140vh;
    }
    .left {
        display: grid;
        justify-content: center;
        align-items: center;
        padding-top: 10vh;
    }
    .left > h1 {
        display: block;
        color: #4f2981;
        padding-top: 5vh;
        transition: all, 0.5s;
        font-size: 11vh;
        font-weight: bolder;
        animation: txt1 ease 1s;
        z-index: 28;
    }
    .right {
        display: grid;
        justify-content: center;
        overflow: hidden;
    }
    .right > img {
        height: 90vh;
        margin-top: 20vh;
        animation: img ease 1s;
        z-index: 28;
    }
    
}
@media (min-width: 769px) and (max-width: 1024px) {
    .container {
        display: grid;
        grid-template-columns: 2fr 1fr;
        height: 100vh;
        margin-top: -100vh;
        overflow: hidden;
    }
    .left {
        display: grid;
        justify-content: center;
        align-items: center;
    }
    .left > h1 {
        display: block;
        color: #4f2981;
        padding: 5vh;
        transition: all, 0.5s;
        font-size: 15vh;
        font-weight: bolder;
        animation: txt 1s ease;
        z-index: 28;
    }

    .right {
        display: grid;
        justify-content: center;
        align-items: end;
    }
    .right > img {
        width: 60vw;
        margin-bottom: -18vh;
        transition: all, 0.5s;
        animation: img 1s ease;
        z-index: 28;
    }
    .right > img:hover {
        margin-bottom: -25vh;
    }
}
@media (min-width: 1025px) {
    .container {
        display: grid;
        grid-template-columns: 2fr 1fr;
        height: 100vh;
        margin-top: -100.5vh;
    }
    .left {
        display: grid;
        text-align: center;
        align-items: center;
        padding-top: 15vh;
    }
    .left > h1 {
        display: block;
        color: #4f2981;
        transition: all, 0.5s;
        font-size: 20vh;
        margin-top: -80vh;
        font-weight: bolder;
        animation: txt 1s ease;
        z-index: 28;
    }

    .right {
        display: grid;
        justify-content: center;
        align-items: end;
        overflow: hidden;
    }
    .right > img {
        height: 100vh;
        margin-bottom: -18vh;
        transition: all, 0.5s;
        z-index: 28;
        animation: img 1s ease;
    }
    .right > img:hover {
        margin-bottom: -25vh;
    }
}