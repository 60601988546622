@media (max-width: 768px) {
    .container {
        display: grid;
        background: #5d5d5d;
        padding: 3vh;
        border-radius: 5vh;
        width: 60vw;
        margin: 5vh auto;
    }
    .container > div > .photo > img {
        width: 90%;
        margin: auto;
        border-radius: 4vh;
        transition: 0.5s;
    }
    .container > div >  .photo {
        background: #212121;
        width: 80%;
        padding: 2vh;
        margin: auto;
        border-radius: 5vh;
        display: grid;
        justify-content: center;
        align-items: center;
        overflow: hidden;
    }
    .name {
        font-size: 4vh;
        color: #4f2981;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .description {
        color: #212121;
        font-size: 3vh;
    }
}
@media (min-width: 769px) and (max-width: 1024px) {
    .container {
        display: grid;
        background: #5d5d5d;
        padding: 5vh;
        border-radius: 5vh;
        width: 60vw;
        margin-left: 12.5vw;
        grid-template-columns: 1fr 9fr;
        margin-top: 5vh;
    }
    .container > div > .photo > img {
        width: 10vw;
        border-radius: 4vh;
        transition: 0.5s;
    }
    .container > div > .photo > img:hover {
        width: 12vw;
    }
    .container > div >  .photo {
        background: #212121;
        width: 11vw;
        height: 23vh;
        border-radius: 5vh;
        display: grid;
        justify-content: center;
        align-items: center;
        overflow: hidden;
    }
    .name {
        font-size: 5vh;
        color: #212121;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .description {
        color: #212121;
        font-size: 4vh;
        padding: 5vh;
    }
}
@media (min-width: 1025px) {

    .container {
        display: grid;
        background: #5d5d5d;
        padding: 5vh;
        border-radius: 5vh;
        width: 60vw;
        margin-left: 12.5vw;
        grid-template-columns: 1fr 9fr;
        margin-top: 5vh;
    }
    .container > div > .photo > img {
        width: 10vw;
        border-radius: 4vh;
        transition: 0.5s;
    }
    .container > div > .photo > img:hover {
        width: 12vw;
    }
    .container > div >  .photo {
        background: #212121;
        width: 11vw;
        height: 23vh;
        border-radius: 5vh;
        display: grid;
        justify-content: center;
        align-items: center;
        overflow: hidden;
    }
    .name {
        font-size: 5vh;
        color: #212121;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .description {
        color: #212121;
        font-size: 4vh;
        padding: 5vh;
    }
}
