@media (max-width: 768px) {
    .container {
        display: grid;
        background-position: center;
        height: 140vh;
        margin-top: -140vh;
        padding-top: 8vh;
    }
    .right {
        display: grid;
        justify-content: center;
        order: 2;
    }
    .right > img {
        height: 50vh;
        transition: all, 1s;
        animation: img 1s ease;
        z-index: 28;
    }
    .left {
        display: grid;
        justify-content: center;
        align-items: center;
        order: 1;
        transition: all, 1s;
    }
    .left > h1 {
        display: block;
        color: #4f2981;
        transition: all, 0.5s;
        z-index: 28;
        font-size: 7vh;
        font-weight: bolder;
        animation: fromBottom 1s ease;
    }
    


}
@media (min-width: 769px) and (max-width: 1024px) {
    .container {
        display: grid;
        grid-template-columns: 1fr 1fr;
        padding: 5vh;
        height: 140vh;
        margin-top: -140vh;
    }
    .right {
        display: grid;
        justify-content: center;
        align-items: center;
        padding-top: 10vh;
        
    }
    .right > img {
        width: 40vw;
        transition: all, 1s;
        z-index: 28;
        animation: img 1s ease;
    }
    .right > img:hover {
        rotate: 15deg;
    }
    .left {
        padding: 20vh 5vh 0 10vh;
        height: 40vh;
        display: block;
        justify-content: center;
        align-items: center;
        transition: all, 1s;
        margin-left: -8vh;
    }
    .left > h1 {
        display: block;
        color: #4f2981;
        z-index: 28;
        transition: all, 0.5s;
        font-size: 14vh;
        font-weight: bolder;
        animation: fromBottom 1s ease;
    }
}
@media (min-width: 1025px) {
    .container {
        display: grid;
        padding: 5vh;
        grid-template-columns: 1fr 1fr;
        height: 140vh;
        margin-top: -140vh;
    }
    .right {
        display: grid;
        justify-content: center;
        align-items: center;
        padding-top: 10vh;
        order: 2;
    }
    .right > img {
        width: 40vw;
        transition: all, 1s;
        animation: img 1s ease;
        z-index: 28;
    }
    .right > img:hover {
        rotate: 15deg;
    }
    .left {
        display: grid;
        text-align: center;
        align-items: center;
        padding-top: 15vh;
        order: 1;
        height: 55vh;
        transition: all, 1s;
    }
    .left > h1 {
        display: block;
        color: #4f2981;
        z-index: 28;
        transition: all, 0.5s;
        font-size: 15vh;
        font-weight: bolder;
        animation: fromBottom 1s ease;
    }
}
