@media (max-width: 768px) {
    .container{
        display: grid;
        justify-content: center;
        align-items: center;
        background: #212121;
    }
    .container > img {
        width: 85vw;
    }
    .feedbackContainer {
        background: #212121;
        padding: 4vh;
    }
    .feedback {
        padding: 5vh;
        background: #5d5d5d;
        border-radius: 15vh;
        font-size: 3vh;
        color: #212121;
    }
}
@media (min-width: 769px) and (max-width: 1024px) {
    .container{
        display: grid;
        padding: 10vh;
        justify-content: center;
        align-items: center;
        background: #212121;
    }
    .container > img {
        width: 85vw;
    }
    .feedbackContainer {
        background: #212121;
        padding: 4vh;
    }
    .feedback {
        padding: 5vh;
        background: #5d5d5d;
        border-radius: 15vh;
        margin: 5vh 5vh 0 5vh;
        font-size: 3vh;
        color: #212121;
    }
}
@media (min-width: 1025px) {

    .container{
        display: grid;
        padding: 10vh;
        justify-content: center;
        align-items: center;
        background: #212121;
    }
    .container > img {
        width: 85vw;
    }
    .feedbackContainer {
        background: #212121;
        padding: 4vh;
    }
    .feedback {
        padding: 5vh;
        background: #5d5d5d;
        border-radius: 15vh;
        margin: 5vh 5vh 0 5vh;
        font-size: 3vh;
        color: #212121;
    }
}
