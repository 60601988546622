@media (max-width: 768px) {
    .container {
        background: #212121;
        display: grid;
    }
    .left > img {
        width: 90vw;
        margin-top: 7.5vh;
        transition: all, 1s;
    }
    .left {
        display: grid;
        justify-content: center;
        align-items: center;
    }
    .container > .rightCont > p {
        font-size: 3.5vh;
        background: #5d5d5d;
        color: #212121;
        border-radius: 5vh;
        padding: 5vh;
        margin: 2vh;
        transition: all, 1s;
        display: block;
    }
    .container > .rightCont > p > div {
        display: inline-block;
        color: #fc7c33;
        opacity: 0.8;
    }
    .container > .rightCont > p > .second {
        display: inline-block;
        color: #4f2981;
        opacity: 1;
    }
}
@media (min-width: 769px) and (max-width: 1024px) {
    .container {
        background: #212121;
        display: grid;
        grid-template-columns: 1fr 1fr;
    }
    .left > img {
        width: 40vw;
        margin-top: 7.5vh;
        margin-left: 10vh;
        transition: all, 1s;
    }
    .left > img:hover {
        rotate: 10deg;
    }
    .container > .rightCont > p {
        font-size: 5vh;
        background: #5d5d5d;
        color: #212121;
        border-radius: 5vh;
        padding: 5vh;
        margin: 7vh;
        display: block;
        transition: all, 1s;
    }
    .container > .rightCont > p:hover {
        box-shadow: #702ACF 0 0 10px;
    }
    .container > .rightCont > p > div {
        display: inline-block;
        color: #fc7c33;
        opacity: 0.8;
    }
    .container > .rightCont > p > .second {
        display: inline-block;
        color: #4f2981;
        opacity: 1;
    }
}
@media (min-width: 1025px) {

    .container {
        background: #212121;
        display: grid;
        grid-template-columns: 1fr 1fr;
    }
    .left > img {
        width: 40vw;
        margin-top: 7.5vh;
        margin-left: 10vh;
        transition: all, 1s;
    }
    .left > img:hover {
        rotate: 10deg;
    }
    .container > .rightCont > p {
        font-size: 5vh;
        background: #5d5d5d;
        color: #212121;
        border-radius: 5vh;
        padding: 5vh;
        margin: 7vh;
        transition: all, 1s;
        display: block;
    }
    .container > .rightCont > p:hover {
        box-shadow: #702ACF 0 0 10px;
    }
    .container > .rightCont > p > div {
        display: inline-block;
        color: #fc7c33;
        opacity: 0.8;
    }
    .container > .rightCont > p > .second {
        display: inline-block;
        color: #4f2981;
        opacity: 1;
    }
}
