.sky {
    position: absolute;
    width: 100%;
    height: 100vh;
    background-image: url("../../images/sky.webp");
    background-position: center center;
    background-repeat: no-repeat;
    background-size: 100vw; /* Голубой фон цвета неба */
    overflow: hidden !important;
    cursor: none;
    display: grid;
    justify-content: center;
    align-content: center;
}

.textBlock {
    text-align: center;
    padding-top: 10vh;
}

.title {
    font-size: 6vw;
    color: #fff;
    font-weight: bolder;
}

.subtitle {
    font-size: 4vw;
    color: #fff;
    font-weight: bold;
}

.cloudTrail {
    position: absolute;
    width: 60px; /* Ширина следа облаков */
    height: 60px; /* Высота следа облаков */
    background: url('../../images/cloun.png') no-repeat center center;
    background-size: contain;
    opacity: 0.8;
    pointer-events: none; /* Облака не взаимодействуют с курсором */
    animation: riseAndFade 5s forwards;
    z-index: 2;
}

.buttonLega {
    width: 35vw;
    border-radius: 10vh;
    height: 10vh;
    color: #49a4d6;
    font-weight: bold;
    font-size: 4vh;
    margin: auto;
    background: #d4dbe5;
    border: 2px solid #49a4d6;
    box-shadow: 0 0 10px #49a4d6;
    transition: 1s;
    cursor: pointer;
}
.buttonLega:hover {
    background: #49a4d6;
    color: #d4dbe5;
}

@keyframes riseAndFade {
    0% {
        transform: scale(1);
        opacity: 0.6;
    }
    100% {
        transform: scale(3);
        opacity: 0;
    }
}
.honey1, .honey2, .honey3, .honey4 {
    position: absolute;
    width: 60vw;
}
.honey1, .honey2 {
    top: -20vh;
}
.honey3, .honey4 {
    top: 50vh;
}
.honey1 {
    left: -18vw;
    rotate: 160deg;
}
.honey2 {
    right: -20vw;
    width: 65vw;
    rotate: 200deg;
}
.honey3 {
    left: -30vw;
    width: 80vw;
    rotate: 15deg;
}
.honey4 {
    right: -20vw;

}
