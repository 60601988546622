@media (max-width: 768px) {
    .menu {
        height: 10vh;
        width: 30vw;
        position: absolute;
        top: 90vh;
        z-index: 99999999999;
        left: 35vw;
        opacity: 0.8;
    }
    .otstup {
        height: 150vh;
        background: #212121;
        align-content: center;
        justify-content: center;
    }
    .sector1 {
        background: #5d5d5d;
        font-size: 9vw;
        font-weight: bolder;
        color: #212121;
    }
    .sector1 > div:nth-child(1) {
        text-align: left;
        transition: none;
        backface-visibility: hidden;
        transform-origin: bottom center;
    }
    .sector1 > div:nth-child(2) {
        text-align: center;
        backface-visibility: hidden;
        transform-origin: bottom center;
    }
    .sector1 > div:nth-child(3) {
        text-align: right;
        backface-visibility: hidden;
        transform-origin: bottom center;
    }
    .sector1 > div:nth-child(4) {
        text-align: center;
        backface-visibility: hidden;
        transform-origin: bottom center;
    }
    .makingAll {
        background: #212121;
        height: 120vh;
    }
    .makingContainer {
        display: grid;
        grid-template-columns: 1fr 1fr;
    }
    .makingLeft {
        display: grid;
        transition: none !important;
        width: 120%;
        font-size: 2vw;
        padding: 0 2vw 0 3vw;
        color: #212121;
        background: #5d5d5d;
        border-radius: 0 5vw 5vw 0;
    }
    .arm {
        width: 55vw;
        display: block;
        translate: 14vw;
        margin: auto;
    }
    .makingName {
        font-size: 9vw;
        text-align: center;
        color: #4f2981;
    }
    .advantages > div:first-child{
        display: grid;
        background: linear-gradient(0deg, rgba(79,41,129,1) 0%, rgba(252,124,51,1)  100%);
        grid-template-columns: 1fr 1fr;
        height: 100vh;
        z-index: 2;
    }
    .advArm {
        width: 60vw;
        display: block;
        translate: -14vw;
        margin: auto;
        transform: scaleX(-1);
    }
    .reviews {
        width: 100%;
        overflow: hidden;
        border-radius: 5vh;
        color: #212121;
    }
    .review {
        height: 60vh;
        width: 50vw;
        display: grid;
        border-radius: 5vh;
        grid-template-columns: 20vw 30vw;
        overflow: hidden;
        background: linear-gradient(0deg, rgba(252,124,51,1) 0%, rgba(79,41,129,1) 100%);
    }
    .unique {
        margin-top: 1vh;
        display: grid;
        grid-template-columns: 80px auto;
    }
    .uniqueSVG {
        display: flex;
        justify-content: center;
        align-content: center;
        justify-items: center;
        align-items: center;
    }
    .leftReview > img {
        height: 60vh;
    }
    .leftReview {
        display: grid;
        justify-content: center;
        overflow: hidden;
    }
    .rightReview {
        padding: 1vw;
    }
    .rightReview > h3{
        font-size: 4vh;
    }
    .rightReview > p {
        font-size: 3vh;
    }
    .newSector {
        height: 100vh;
        display: grid;
        grid-template-rows: repeat(4, 1fr);
        font-size: 4.5vw;
        background: #5d5d5d;
        margin-top: -2vh;
        font-weight: bold;
    }
    .computerBlock {
        display: grid;
        grid-template-columns: 40vw 60vw;
        height: 100vh;
    }
    .leftComp {
        margin: auto;
    }
    .leftComp > img {
        width: 40vw;
        margin: auto;
        display: block;
    }
    .rightComp {
        margin: auto auto auto -21vw;
    }
    .rightComp > h3 {
        color: #fc7c33;
        opacity: 0.8;
        font-size: 4vw;
        font-weight: bolder;
        text-shadow: 0 0 10px;
    }
    .feedBack {
        height: 100vh;
        background: #5d5d5d;
        white-space: nowrap;
        align-content: center;
        padding: 4vh;
        display: grid;
        grid-template-columns: repeat(6,1fr);
        grid-column-gap: 2vw;
    }
    .feed {
        background: #212121;
        display: grid;
        width: 43vw;
        border-radius: 5vh;
        height: 50vh;
        padding: 5vh;
        grid-template-rows: 1fr 6fr;
    }
    .leftName {
        width: 10vw;
    }
    .leftName > img {
        width: 10vw;
        border-radius: 5vh;
    }
    .feedName {
        display: grid;
        grid-template-columns: 1fr 3fr;
        height: 20vh;
    }
    .rightName {
        color: #4f2981;
        font-size: 2vw;
        padding-left: 2vw;
    }
    .feedStars > svg {
        margin-right: 0.5vw;
    }
    .feedStars {
        margin-top: auto;
    }
    .feedDescr {
        color: #5d5d5d;
        white-space: normal;
        font-size: 1.5vw;
        display: grid;
        padding-top: 3vh;
        height: 30vh;
        grid-template-rows: 20vh 10vh;
    }
    .work1 {
        height: 50vh !important;
        width: 65vw;
        overflow: hidden !important;
        display: grid;
        justify-content: center;
    }
    .work1 > img {
        width: 65vw;
        transition: all, 1s;
        opacity: 0.7;
    }
    .work1 > img:hover {
        width: 70vw;
        opacity: 1;
    }
    .work2 {
        height: 50vh !important;
        width: 25vw;
        overflow: hidden !important;
        display: grid;
        justify-content: center;
    }
    .work2 > img {
        width: 25vw;
        transition: all, 1s;
        opacity: 0.7;
    }
    .work2 > img:hover {
        width: 30vw;
        opacity: 1;
    }
    .work3 {
        height: 90vh !important;
        width: 91vw;
        overflow: hidden !important;
        display: grid;
        justify-content: center;
    }
    .work3 > img {
        width: 91vw;
        margin-top: -20vh;
        transition: all, 1s;
        opacity: 0.7;
    }
    .work3 > img:hover {
        width: 95vw;
        opacity: 1;
    }
    .work4 {
        height: 50vh !important;
        width: 35vw;
        overflow: hidden !important;
        display: grid;
        justify-content: center;
    }
    .work4 > img {
        width: 35vw;
        transition: all, 1s;
        opacity: 0.7;
    }
    .work4 > img:hover {
        width: 40vw;
        opacity: 1;
    }
    .work5 {
        height: 50vh !important;
        width: 55vw;
        overflow: hidden !important;
        display: grid;
        justify-content: center;
    }
    .work5 > img {
        width: 55vw;
        transition: all, 1s;
        opacity: 0.7;
    }
    .work5 > img:hover {
        width: 60vw;
        opacity: 1;
    }
    .casesBlock {
        display: grid;
        grid-template-rows: 50vh 90vh 50vh;
        grid-row-gap: 2vh;
        padding: 1vw;
        justify-content: center;
    }
    .first, .second {
        display: grid;
    }
    .first {
        grid-template-columns: 65vw 25vw;
        grid-column-gap: 1vw;
    }
    .second {
        grid-template-columns: 35vw 55vw;
        grid-column-gap: 1vw;
    }
    .buttonBlock {
        height: 100vh;
        display: grid;
        justify-content: center;
        align-content: center;
    }
    .buttonBlock > button {
        height: 60vh;
        width: 80vw;
        color: #4f2981;
        box-shadow: 0 0 10px #4f2981;
        background: #212121;
        border-radius: 10vh;
        border: 8px solid #4f2981;
        font-size: 5vw;
        font-weight: bolder;
        transition: 1s;
    }
    .buttonBlock > button:hover {
        background: #4f2981;
        color: #212121;
    }
    .process {
        display: grid;
        grid-template-columns: 1fr 1fr;
        height: 100vh;
    }
    .leftProc {
        display: grid;
        justify-items: center;
        align-items: center;
    }
    .leftProc > div {
        width: 30vw;
        height: 80vh;
        display: grid;
        text-align: center;
        color: #212121;
        border-radius: 5vh !important;
        font-size: 3vh;
    }
    .leftProc > div > div:hover {
        color: #4f2981;
        background: #212121;
        cursor: pointer;
    }
    .leftProc > div > div {
        border-bottom: 2px solid #212121;
        justify-content: center;
        align-items: center;
        border-radius: 5vh;
        display: grid;
        transition: 0.5s !important;
    }
    .rightProc {
        margin: auto;
    }
    .item {
        grid-template-rows: 40vh 30vh;
        width: 40vw;
        height: 70vh;
        background: #5d5d5d;
        border-radius: 4vh;
        overflow: hidden;
        margin: auto;
        transition: 1s;
    }
    .item > div > img {
        width: 40vw;
        margin: auto;
    }
    .item > div:first-child {
        width: 40vw;
        height: 40vh;
        overflow: hidden;
        justify-items: center;
        align-items: center;
        justify-content: center;
        align-content: center;
        display: grid;
    }
    .item:nth-child(4) > div:first-child {
        width: 40vw;
        height: 40vh;
        overflow: hidden;
        justify-items: center;
        align-items: center;
        align-content: start;
        display: grid;
    }
    .item > div:last-child {
        color: #212121;
    }
    .item > div:last-child > h3 {
        color: #4f2981;
        font-size: 2vw;
        margin-right: 2vw;
        margin-left: 2vw;
    }
    .item > div:last-child > p {
        font-size: 1.2vw;
        margin-left: 2vw;
        margin-right: 2vw;
    }
    .buttonLega {
        width: 35vw;
        border-radius: 10vh;
        height: 10vh;
        color: #4f2981;
        font-weight: bold;
        font-size: 4vh;
        margin: auto;
        background: #212121;
        border: 2px solid #4f2981;
        box-shadow: 0 0 10px #4f2981;
        transition: 1s;
        cursor: pointer;
    }
    .buttonLega:hover {
        background: #4f2981;
        color: #212121;
    }
}

/* Стили для планшетов (от 769px до 1024px) */
@media (min-width: 769px) and (max-width: 1024px) {
    .menu {
        height: 10vh;
        width: 30vw;
        position: absolute;
        top: 90vh;
        z-index: 99999999999;
        left: 35vw;
        opacity: 0.8;
    }
    .otstup {
        height: 150vh;
        background: #212121;
        align-content: center;
        justify-content: center;
    }
    .sector1 {
        background: #5d5d5d;
        font-size: 9vw;
        font-weight: bolder;
        color: #212121;
    }
    .sector1 > div:nth-child(1) {
        text-align: left;
        transition: none;
        backface-visibility: hidden;
        transform-origin: bottom center;
    }
    .sector1 > div:nth-child(2) {
        text-align: center;
        backface-visibility: hidden;
        transform-origin: bottom center;
    }
    .sector1 > div:nth-child(3) {
        text-align: right;
        backface-visibility: hidden;
        transform-origin: bottom center;
    }
    .sector1 > div:nth-child(4) {
        text-align: center;
        backface-visibility: hidden;
        transform-origin: bottom center;
    }
    .makingAll {
        background: #212121;
        height: 120vh;
    }
    .makingContainer {
        display: grid;
        grid-template-columns: 1fr 1fr;
    }
    .makingLeft {
        display: grid;
        transition: none !important;
        width: 120%;
        font-size: 2vw;
        padding: 0 2vw 0 3vw;
        color: #212121;
        background: #5d5d5d;
        border-radius: 0 5vw 5vw 0;
    }
    .arm {
        width: 55vw;
        display: block;
        translate: 14vw;
        margin: auto;
    }
    .makingName {
        font-size: 9vw;
        text-align: center;
        color: #4f2981;
    }
    .advantages > div:first-child{
        display: grid;
        background: linear-gradient(0deg, rgba(79,41,129,1) 0%, rgba(252,124,51,1)  100%);
        grid-template-columns: 1fr 1fr;
        height: 100vh;
        z-index: 2;
    }
    .advArm {
        width: 60vw;
        display: block;
        translate: -14vw;
        margin: auto;
        transform: scaleX(-1);
    }
    .left {
        margin: auto;
    }
    .right {
        margin: auto;
    }
    .viewWindow {
        width: 50vw;
        height: 60vh;
        margin: auto auto auto -14vw;
        border-radius: 5vh;
        z-index: 10;
        background: #212121;
    
    }
    .reviews {
        width: 100%;
        height: 100%;
        overflow: hidden;
        border-radius: 5vh;
        color: #212121;
    }
    .review {
        height: 60vh;
        width: 50vw;
        display: grid;
        border-radius: 5vh;
        grid-template-columns: 20vw 30vw;
        overflow: hidden;
        background: linear-gradient(0deg, rgba(252,124,51,1) 0%, rgba(79,41,129,1) 100%);
    }
    .unique {
        margin-top: 1vh;
        display: grid;
        grid-template-columns: 80px auto;
    }
    .uniqueSVG {
        display: flex;
        justify-content: center;
        align-content: center;
        justify-items: center;
        align-items: center;
    }
    .leftReview > img {
        height: 60vh;
    }
    .leftReview {
        display: grid;
        justify-content: center;
        overflow: hidden;
    }
    .rightReview {
        padding: 1vw;
    }
    .rightReview > h3{
        font-size: 4vh;
    }
    .rightReview > p {
        font-size: 3vh;
    }
    .newSector {
        height: 100vh;
        display: grid;
        grid-template-rows: repeat(4, 1fr);
        font-size: 4.5vw;
        background: #5d5d5d;
        margin-top: -2vh;
        font-weight: bold;
    }
    .computerBlock {
        display: grid;
        grid-template-columns: 40vw 60vw;
        height: 100vh;
    }
    .leftComp {
        margin: auto;
    }
    .leftComp > img {
        width: 40vw;
        margin: auto;
        display: block;
    }
    .rightComp {
        margin: auto auto auto -21vw;
    }
    .rightComp > h3 {
        color: #fc7c33;
        opacity: 0.8;
        font-size: 4vw;
        font-weight: bolder;
        text-shadow: 0 0 10px;
    }
    .feedBack {
        height: 100vh;
        background: #5d5d5d;
        white-space: nowrap;
        align-content: center;
        padding: 4vh;
        display: grid;
        grid-template-columns: repeat(6,1fr);
        grid-column-gap: 2vw;
    }
    .feed {
        background: #212121;
        display: grid;
        width: 43vw;
        border-radius: 5vh;
        height: 50vh;
        padding: 5vh;
        grid-template-rows: 1fr 6fr;
    }
    .leftName {
        width: 10vw;
    }
    .leftName > img {
        width: 10vw;
        border-radius: 5vh;
    }
    .feedName {
        display: grid;
        grid-template-columns: 1fr 3fr;
        height: 20vh;
    }
    .rightName {
        color: #4f2981;
        font-size: 2vw;
        padding-left: 2vw;
    }
    .feedStars > svg {
        margin-right: 0.5vw;
    }
    .feedStars {
        margin-top: auto;
    }
    .feedDescr {
        color: #5d5d5d;
        white-space: normal;
        font-size: 1.5vw;
        display: grid;
        padding-top: 3vh;
        height: 30vh;
        grid-template-rows: 20vh 10vh;
    }
    .work1 {
        height: 50vh !important;
        width: 65vw;
        overflow: hidden !important;
        display: grid;
        justify-content: center;
    }
    .work1 > img {
        width: 65vw;
        transition: all, 1s;
        opacity: 0.7;
    }
    .work1 > img:hover {
        width: 70vw;
        opacity: 1;
    }
    .work2 {
        height: 50vh !important;
        width: 25vw;
        overflow: hidden !important;
        display: grid;
        justify-content: center;
    }
    .work2 > img {
        width: 25vw;
        transition: all, 1s;
        opacity: 0.7;
    }
    .work2 > img:hover {
        width: 30vw;
        opacity: 1;
    }
    .work3 {
        height: 90vh !important;
        width: 91vw;
        overflow: hidden !important;
        display: grid;
        justify-content: center;
    }
    .work3 > img {
        width: 91vw;
        margin-top: -20vh;
        transition: all, 1s;
        opacity: 0.7;
    }
    .work3 > img:hover {
        width: 95vw;
        opacity: 1;
    }
    .work4 {
        height: 50vh !important;
        width: 35vw;
        overflow: hidden !important;
        display: grid;
        justify-content: center;
    }
    .work4 > img {
        width: 35vw;
        transition: all, 1s;
        opacity: 0.7;
    }
    .work4 > img:hover {
        width: 40vw;
        opacity: 1;
    }
    .work5 {
        height: 50vh !important;
        width: 55vw;
        overflow: hidden !important;
        display: grid;
        justify-content: center;
    }
    .work5 > img {
        width: 55vw;
        transition: all, 1s;
        opacity: 0.7;
    }
    .work5 > img:hover {
        width: 60vw;
        opacity: 1;
    }
    .casesBlock {
        display: grid;
        grid-template-rows: 50vh 90vh 50vh;
        grid-row-gap: 2vh;
        padding: 1vw;
        justify-content: center;
    }
    .first, .second {
        display: grid;
    }
    .first {
        grid-template-columns: 65vw 25vw;
        grid-column-gap: 1vw;
    }
    .second {
        grid-template-columns: 35vw 55vw;
        grid-column-gap: 1vw;
    }
    .buttonBlock {
        height: 100vh;
        display: grid;
        justify-content: center;
        align-content: center;
    }
    .buttonBlock > button {
        height: 60vh;
        width: 80vw;
        color: #4f2981;
        box-shadow: 0 0 10px #4f2981;
        background: #212121;
        border-radius: 10vh;
        border: 8px solid #4f2981;
        font-size: 5vw;
        font-weight: bolder;
        transition: 1s;
    }
    .buttonBlock > button:hover {
        background: #4f2981;
        color: #212121;
    }
    .process {
        display: grid;
        grid-template-columns: 1fr 1fr;
        height: 100vh;
    }
    .leftProc {
        display: grid;
        justify-items: center;
        align-items: center;
    }
    .leftProc > div {
        width: 30vw;
        height: 80vh;
        display: grid;
        text-align: center;
        color: #212121;
        border-radius: 5vh !important;
        font-size: 3vh;
    }
    .leftProc > div > div:hover {
        color: #4f2981;
        background: #212121;
        cursor: pointer;
    }
    .leftProc > div > div {
        border-bottom: 2px solid #212121;
        justify-content: center;
        align-items: center;
        border-radius: 5vh;
        display: grid;
        transition: 0.5s !important;
    }
    .rightProc {
        margin: auto;
    }
    .item {
        grid-template-rows: 40vh 30vh;
        width: 40vw;
        height: 70vh;
        background: #5d5d5d;
        border-radius: 4vh;
        overflow: hidden;
        margin: auto;
        transition: 1s;
    }
    .item > div > img {
        width: 40vw;
        margin: auto;
    }
    .item > div:first-child {
        width: 40vw;
        height: 40vh;
        overflow: hidden;
        justify-items: center;
        align-items: center;
        justify-content: center;
        align-content: center;
        display: grid;
    }
    .item:nth-child(4) > div:first-child {
        width: 40vw;
        height: 40vh;
        overflow: hidden;
        justify-items: center;
        align-items: center;
        align-content: start;
        display: grid;
    }
    .item > div:last-child {
        color: #212121;
    }
    .item > div:last-child > h3 {
        color: #4f2981;
        font-size: 2vw;
        margin-right: 2vw;
        margin-left: 2vw;
    }
    .item > div:last-child > p {
        font-size: 1.2vw;
        margin-left: 2vw;
        margin-right: 2vw;
    }
    .buttonLega {
        width: 35vw;
        border-radius: 10vh;
        height: 10vh;
        color: #4f2981;
        font-weight: bold;
        font-size: 4vh;
        margin: auto;
        background: #212121;
        border: 2px solid #4f2981;
        box-shadow: 0 0 10px #4f2981;
        transition: 1s;
        cursor: pointer;
    }
    .buttonLega:hover {
        background: #4f2981;
        color: #212121;
    }
}

/* Стили для компьютеров (минимальная ширина 1025px) */
@media (min-width: 1025px) {
    .menu {
        height: 10vh;
        width: 30vw;
        position: absolute;
        top: 90vh;
        z-index: 99999999999;
        left: 35vw;
        opacity: 0.8;
    }
    .otstup {
        height: 150vh;
        background: #212121;
        align-content: center;
        justify-content: center;
    }
    .sector1 {
        background: #5d5d5d;
        font-size: 9vw;
        font-weight: bolder;
        color: #212121;
    }
    .sector1 > div:nth-child(1) {
        text-align: left;
        transition: none;
        backface-visibility: hidden;
        transform-origin: bottom center;
    }
    .sector1 > div:nth-child(2) {
        text-align: center;
        backface-visibility: hidden;
        transform-origin: bottom center;
    }
    .sector1 > div:nth-child(3) {
        text-align: right;
        backface-visibility: hidden;
        transform-origin: bottom center;
    }
    .sector1 > div:nth-child(4) {
        text-align: center;
        backface-visibility: hidden;
        transform-origin: bottom center;
    }
    .makingAll {
        background: #212121;
        height: 120vh;
    }
    .makingContainer {
        display: grid;
        grid-template-columns: 1fr 1fr;
    }
    .makingLeft {
        display: grid;
        transition: none !important;
        width: 120%;
        font-size: 2vw;
        padding: 0 2vw 0 3vw;
        color: #212121;
        background: #5d5d5d;
        border-radius: 0 5vw 5vw 0;
    }
    .arm {
        width: 55vw;
        display: block;
        translate: 14vw;
        margin: auto;
    }
    .makingName {
        font-size: 9vw;
        text-align: center;
        color: #4f2981;
    }
    .advantages > div:first-child{
        display: grid;
        background: linear-gradient(0deg, rgba(79,41,129,1) 0%, rgba(252,124,51,1)  100%);
        grid-template-columns: 1fr 1fr;
        height: 100vh;
        z-index: 2;
    }
    .advArm {
        width: 60vw;
        display: block;
        translate: -14vw;
        margin: auto;
        transform: scaleX(-1);
    }
    .left {
        margin: auto;
    }
    .right {
        margin: auto;
    }
    .viewWindow {
        width: 50vw;
        height: 60vh;
        margin: auto auto auto -14vw;
        border-radius: 5vh;
        z-index: 10;
        background: #212121;
    
    }
    .reviews {
        width: 100%;
        height: 100%;
        overflow: hidden;
        border-radius: 5vh;
        color: #212121;
    }
    .review {
        height: 60vh;
        width: 50vw;
        display: grid;
        border-radius: 5vh;
        grid-template-columns: 20vw 30vw;
        overflow: hidden;
        background: linear-gradient(0deg, rgba(252,124,51,1) 0%, rgba(79,41,129,1) 100%);
    }
    .unique {
        margin-top: 1vh;
        display: grid;
        grid-template-columns: 80px auto;
    }
    .uniqueSVG {
        display: flex;
        justify-content: center;
        align-content: center;
        justify-items: center;
        align-items: center;
    }
    .leftReview > img {
        height: 60vh;
    }
    .leftReview {
        display: grid;
        justify-content: center;
        overflow: hidden;
    }
    .rightReview {
        padding: 1vw;
    }
    .rightReview > h3{
        font-size: 4vh;
    }
    .rightReview > p {
        font-size: 3vh;
    }
    .newSector {
        min-height: 120vh;
        display: grid;
        grid-template-rows: repeat(4, 1fr);
        font-size: 4.5vw;
        background: #5d5d5d;
        margin-top: -2vh;
        font-weight: bold;
    }
    .computerBlock {
        display: grid;
        grid-template-columns: 40vw 60vw;
        height: 100vh;
    }
    .leftComp {
        margin: auto;
    }
    .leftComp > img {
        width: 40vw;
        margin: auto;
        display: block;
    }
    .rightComp {
        margin: auto auto auto -21vw;
    }
    .rightComp > h3 {
        color: #fc7c33;
        opacity: 0.8;
        font-size: 4vw;
        font-weight: bolder;
        text-shadow: 0 0 10px;
    }
    .feedBack {
        height: 100vh;
        background: #5d5d5d;
        white-space: nowrap;
        align-content: center;
        padding: 4vh;
        display: grid;
        grid-template-columns: repeat(6,1fr);
        grid-column-gap: 2vw;
    }
    .feed {
        background: #212121;
        display: grid;
        width: 43vw;
        border-radius: 5vh;
        height: 50vh;
        padding: 5vh;
        grid-template-rows: 1fr 6fr;
    }
    .leftName {
        width: 10vw;
    }
    .leftName > img {
        width: 10vw;
        border-radius: 5vh;
    }
    .feedName {
        display: grid;
        grid-template-columns: 1fr 3fr;
        height: 20vh;
    }
    .rightName {
        color: #4f2981;
        font-size: 2vw;
        padding-left: 2vw;
    }
    .feedStars > svg {
        margin-right: 0.5vw;
    }
    .feedStars {
        margin-top: auto;
    }
    .feedDescr {
        color: #5d5d5d;
        white-space: normal;
        font-size: 1.5vw;
        display: grid;
        padding-top: 3vh;
        height: 30vh;
        grid-template-rows: 20vh 10vh;
    }
    .work1 {
        height: 50vh !important;
        width: 65vw;
        overflow: hidden !important;
        display: grid;
        justify-content: center;
    }
    .work1 > img {
        width: 65vw;
        transition: all, 1s;
        opacity: 0.7;
    }
    .work1 > img:hover {
        width: 70vw;
        opacity: 1;
    }
    .work2 {
        height: 50vh !important;
        width: 25vw;
        overflow: hidden !important;
        display: grid;
        justify-content: center;
    }
    .work2 > img {
        width: 25vw;
        transition: all, 1s;
        opacity: 0.7;
    }
    .work2 > img:hover {
        width: 30vw;
        opacity: 1;
    }
    .work3 {
        height: 90vh !important;
        width: 91vw;
        overflow: hidden !important;
        display: grid;
        justify-content: center;
    }
    .work3 > img {
        width: 91vw;
        margin-top: -20vh;
        transition: all, 1s;
        opacity: 0.7;
    }
    .work3 > img:hover {
        width: 95vw;
        opacity: 1;
    }
    .work4 {
        height: 50vh !important;
        width: 35vw;
        overflow: hidden !important;
        display: grid;
        justify-content: center;
    }
    .work4 > img {
        width: 35vw;
        transition: all, 1s;
        opacity: 0.7;
    }
    .work4 > img:hover {
        width: 40vw;
        opacity: 1;
    }
    .work5 {
        height: 50vh !important;
        width: 55vw;
        overflow: hidden !important;
        display: grid;
        justify-content: center;
    }
    .work5 > img {
        width: 55vw;
        transition: all, 1s;
        opacity: 0.7;
    }
    .work5 > img:hover {
        width: 60vw;
        opacity: 1;
    }
    .casesBlock {
        display: grid;
        grid-template-rows: 50vh 90vh 50vh;
        grid-row-gap: 2vh;
        padding: 1vw;
        justify-content: center;
    }
    .first, .second {
        display: grid;
    }
    .first {
        grid-template-columns: 65vw 25vw;
        grid-column-gap: 1vw;
    }
    .second {
        grid-template-columns: 35vw 55vw;
        grid-column-gap: 1vw;
    }
    .buttonBlock {
        height: 100vh;
        display: grid;
        justify-content: center;
        align-content: center;
    }
    .buttonBlock > a {
        height: 55vh;
        width: 70vw;
        color: #4f2981;
        box-shadow: 0 0 10px #4f2981;
        background: #212121;
        border-radius: 10vh;
        border: 8px solid #4f2981;
        font-size: 5vw;
        font-weight: bolder;
        transition: 1s;
        cursor: pointer;
        display: grid;
        padding: 5vw;
        align-content: center;
        text-align: center;
        text-decoration: none;
    }
    .buttonBlock > a:hover {
        background: #4f2981;
        color: #212121;
    }
    .process {
        display: grid;
        grid-template-columns: 1fr 1fr;
        height: 100vh;
    }
    .leftProc {
        display: grid;
        justify-items: center;
        align-items: center;
    }
    .leftProc > div {
        width: 30vw;
        height: 80vh;
        display: grid;
        text-align: center;
        color: #212121;
        border-radius: 5vh !important;
        font-size: 3vh;
    }
    .leftProc > div > div:hover {
        color: #4f2981;
        background: #212121;
        cursor: pointer;
    }
    .leftProc > div > div {
        border-bottom: 2px solid #212121;
        justify-content: center;
        align-items: center;
        border-radius: 5vh;
        display: grid;
        transition: 0.5s !important;
    }
    .rightProc {
        margin: auto;
    }
    .item {
        grid-template-rows: 40vh 30vh;
        width: 40vw;
        height: 70vh;
        background: #5d5d5d;
        border-radius: 4vh;
        overflow: hidden;
        margin: auto;
        transition: 1s;
    }
    .item > div > img {
        width: 40vw;
        margin: auto;
    }
    .item > div:first-child {
        width: 40vw;
        height: 40vh;
        overflow: hidden;
        justify-items: center;
        align-items: center;
        justify-content: center;
        align-content: center;
        display: grid;
    }
    .item:nth-child(4) > div:first-child {
        width: 40vw;
        height: 40vh;
        overflow: hidden;
        justify-items: center;
        align-items: center;
        align-content: start;
        display: grid;
    }
    .item > div:last-child {
        color: #212121;
    }
    .item > div:last-child > h3 {
        color: #4f2981;
        font-size: 2vw;
        margin-right: 2vw;
        margin-left: 2vw;
    }
    .item > div:last-child > p {
        font-size: 1.2vw;
        margin-left: 2vw;
        margin-right: 2vw;
    }
    .buttonLega {
        width: 35vw;
        border-radius: 10vh;
        height: 10vh;
        color: #4f2981;
        font-weight: bold;
        font-size: 4vh;
        margin: auto;
        background: #212121;
        border: 2px solid #4f2981;
        box-shadow: 0 0 10px #4f2981;
        transition: 1s;
        cursor: pointer;
    }
    .buttonLega:hover {
        background: #4f2981;
        color: #212121;
    }
}
