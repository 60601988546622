@media (max-width: 768px) {
    .footer {
        font-family: Oswald, sans-serif;
        display: grid;
        background: #212121;
    }
    .top {
        display: grid;
    }
    .namer {
        font-size: 6vh !important;
        display: grid;
        justify-content: center;
        color: #fc7c33 !important;
        height: 12vh !important;
        padding: 0 !important;
        margin: 0 !important;
    }
    .connect > div > svg {
        width: 4vw;
    }
    .container {
        display: grid;
        padding: 0 !important;
        margin: 0;
    }
    .left {
        display: grid;
        justify-content: center;
        grid-row-gap: 4vh;
    }
    .left > div {
        display: grid;
        grid-template-rows: 1fr 1fr;
        justify-content: center;
        align-items: center;
    }
    .naming {
        font-size: 5vh;
        color: #702ACF;
        display: grid;
        text-align: center;
    }
    .left > div > div > input {
        font-size: 3vh;
        height: 6vh;
        background: #212121;
        color: #5d5d5d;
        border-bottom: 2px solid #5d5d5d;
        border-top: none;
        border-right: none;
        border-left: none;
    }
    .left > div > div > input:active {
        border: 2px solid #702ACF;
        border-radius: 5vh;
    }
    .left > div > div > input:focus {
        border: 2px #702ACF;
        border-radius: 5vh;
    }
    .toastMessage {
        width: 85vw;
        border-radius: 2vh;
        margin-top: 4vh;
        margin-left: 2vw;
    }
    .right {
        display: grid;
        grid-row-gap: 3vh;
        justify-content: center;
        align-items: center;
    }
    textarea {
        height:28vh;
        width: 75vw;
        margin-right: 6.5vw;
        margin-left: 6.5vw;
        background: #212121;
        border: 2px solid #5d5d5d;
        border-radius: 5vh;
        resize: none;
        padding: 3vh;
        font-size: 3vh;
        color: #5d5d5d;
    }
    .buttonBlock > button {
        font-size: 3vh;
        width: 90vw;
        margin: auto;
        white-space: nowrap;
        height: 9vh;
        border: 2px solid #702ACF;
        background: #212121;
        color: #702ACF;
        border-radius: 10vh;
        padding: 2vh;
        font-weight: bold;
        box-shadow: #702ACF 0 0 10px;
        cursor: pointer;
        transition: all, 0.5s;
    }
    .buttonBlock > button:hover {
        background: #702ACF;
        color: #212121;
    }
    .buttonBlock {
        display: grid;
        justify-content: center;
    }
    .buttonBlock > label > span {
        white-space: nowrap;
        font-size: 3vh;
    }
    .botContainer {
        display: grid;
        background: #5d5d5d;
        border-radius: 4vh;
        margin: 6vh 6vw 6vh;
        padding: 3vh;
    }
    .connect {
        display: grid;
        grid-template-columns: 2fr 7fr;
    }
    .connect > div > svg {
        width: 15vw;
    }
    .net {
        font-size: 4vh;
        color: #212121;
        display: grid;
        align-items: center;
    }
    .mail {
        font-size: 4vh;
        color: #212121;
        display: grid;
        align-items: center;
    }
    .mail > a {
        font-size: 4vh;
        text-decoration: underline;
        color: #212121 !important;
        display: grid;
        align-items: center;
    }
    input[type=number]::-webkit-inner-spin-button,
    input[type=number]::-webkit-outer-spin-button {
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        margin: 0;
    }
    /* для элемента input c type="checkbox" */
    .customCheckbox>input {
        position: absolute;
        z-index: -1;
        opacity: 0;
    }

    /* для элемента label, связанного с .custom-checkbox */
    .customCheckbox>span {
        display: inline-flex;
        align-items: center;
        user-select: none;
    }

    /* создание в label псевдоэлемента before со следующими стилями */
    .customCheckbox>span::before {
        content: '';
        display: inline-block;
        width: 1em;
        height: 1em;
        flex-shrink: 0;
        flex-grow: 0;
        border: 1px solid #702ACF;
        border-radius: 0.25em;
        margin-right: 0.5em;
        background-repeat: no-repeat;
        background-position: center center;
        background-size: 50% 50%;
    }

    /* стили при наведении курсора на checkbox */
    .customCheckbox>input:not(:disabled):not(:checked)+span:hover::before {
        border-color: #702ACF;
    }

    /* стили для активного чекбокса (при нажатии на него) */
    .customCheckbox>input:not(:disabled):active+span::before {
        background-color: #702ACF;
        border-color: #702ACF;
    }

    /* стили для чекбокса, находящегося в фокусе */
    .customCheckbox>input:focus+span::before {
        box-shadow: 0 0 0 0.2rem rgba(79, 41, 129, 0.25);
    }

    /* стили для чекбокса, находящегося в фокусе и не находящегося в состоянии checked */
    .customCheckbox>input:focus:not(:checked)+span::before {
        border-color: #702ACF;
    }

    /* стили для чекбокса, находящегося в состоянии checked */
    .customCheckbox>input:checked+span::before {
        border-color: #702ACF;
        background-color: #702ACF;
        background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3e%3c/svg%3e");
    }
    .customCheckbox {
        display: grid;
        padding: 3vh;
        justify-content: end;
    }
    .customCheckbox > span {
        color: #702ACF;
        font-size: 4vh;
    }
}
@media (min-width: 769px) and (max-width: 1024px) {
    .footer {
        font-family: Oswald, sans-serif;
        display: grid;
        background: #212121;
        padding: 0 5vh 5vh 5vh;
    }
    .top {
        display: grid;
        grid-template-rows: 1fr 6fr 1fr;
    }
    .namer {
        font-size: 9vh !important;
        display: grid;
        justify-content: center;
        color: #fc7c33 !important;
        opacity: 0.8;
        align-items: center;
    }
    .connect > div > svg {
        width: 4vw;
    }
    .container {
        display: grid;
        grid-template-rows: 1fr 3fr;
        padding: 2vh;
        grid-row-gap: 5vh;
    }
    .left {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        justify-content: center;
        align-items: start;
    }
    .left > div {
        display: grid;
        grid-template-rows: 1fr 1fr;
        justify-content: center;
        align-items: center;
    }
    .naming {
        font-size: 5vh;
        color: #702ACF;
        display: grid;
        justify-content: center;
        align-items: center;
    }
    .left > div > div > input {
        font-size: 3vh;
        height: 6vh;
        background: #212121;
        color: #5d5d5d;
        border-bottom: 2px solid #5d5d5d;
        border-right: transparent;
    }
    .left > div > div > input:active {
        border: 2px solid #702ACF;
        border-radius: 5vh;
    }
    .left > div > div > input:focus {
        border: 2px #702ACF;
        border-radius: 5vh;
    }
    .right {
        display: grid;
        grid-template-rows: 1fr 5fr;
        justify-content: center;
        align-items: center;
    }
    textarea {
        margin-top: -2vh;
        height:28vh;
        width: 75vw;
        background: #212121;
        border: 2px solid #5d5d5d;
        border-radius: 5vh;
        resize: none;
        padding: 3vh;
        font-size: 3vh;
        color: #5d5d5d;
    }
    .buttonBlock > button {
        font-size: 4vh;
        width: 30vw;
        white-space: nowrap;
        border: 2px solid #702ACF;
        background: #212121;
        color: #702ACF;
        margin-left: 5vh;
        border-radius: 10vh;
        padding: 2vh;
        font-weight: bold;
        /*opacity: 0.49;*/
        box-shadow: #702ACF 0 0 10px;
        cursor: pointer;
        transition: all, 0.5s;
    }
    .buttonBlock > button:hover {
        background: #702ACF;
        color: #212121;
    }
    .buttonBlock {
        display: grid;
        justify-content: center;
        align-items: start;
        height: 5vh;
        margin-top: -4vh;
    }
    .botContainer {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr;
        background: #5d5d5d;
        border-radius: 10vh;
        padding: 3vh;
    }
    .connect {
        display: grid;
        grid-template-columns: 2fr 7fr;
    }
    .net {
        font-size: 4vh;
        color: #212121;
        display: grid;
        align-items: center;
    }
    .mail {
        font-size: 4vh;
        color: #212121;
        display: grid;
        align-items: center;
    }
    .mail > a {
        font-size: 4vh;
        text-decoration: underline;
        color: #212121 !important;
        display: grid;
        align-items: center;
    }
    input[type=number]::-webkit-inner-spin-button,
    input[type=number]::-webkit-outer-spin-button {
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        margin: 0;
    }
    /* для элемента input c type="checkbox" */
    .customCheckbox>input {
        position: absolute;
        z-index: -1;
        opacity: 0;
    }

    /* для элемента label, связанного с .custom-checkbox */
    .customCheckbox>span {
        display: inline-flex;
        align-items: center;
        user-select: none;
    }

    /* создание в label псевдоэлемента before со следующими стилями */
    .customCheckbox>span::before {
        content: '';
        display: inline-block;
        width: 1em;
        height: 1em;
        flex-shrink: 0;
        flex-grow: 0;
        border: 1px solid #702ACF;
        border-radius: 0.25em;
        margin-right: 0.5em;
        background-repeat: no-repeat;
        background-position: center center;
        background-size: 50% 50%;
    }

    /* стили при наведении курсора на checkbox */
    .customCheckbox>input:not(:disabled):not(:checked)+span:hover::before {
        border-color: #702ACF;
    }

    /* стили для активного чекбокса (при нажатии на него) */
    .customCheckbox>input:not(:disabled):active+span::before {
        background-color: #702ACF;
        border-color: #702ACF;
    }

    /* стили для чекбокса, находящегося в фокусе */
    .customCheckbox>input:focus+span::before {
        box-shadow: 0 0 0 0.2rem rgba(79, 41, 129, 0.25);
    }

    /* стили для чекбокса, находящегося в фокусе и не находящегося в состоянии checked */
    .customCheckbox>input:focus:not(:checked)+span::before {
        border-color: #702ACF;
    }

    /* стили для чекбокса, находящегося в состоянии checked */
    .customCheckbox>input:checked+span::before {
        border-color: #702ACF;
        background-color: #702ACF;
        background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3e%3c/svg%3e");
    }
    .customCheckbox {
        display: grid;
        padding: 3vh;
        justify-content: end;
    }
    .customCheckbox > span {
        color: #702ACF;
        font-size: 4vh;
    }
    .buttonBlock {
        display: grid;
        grid-template-columns: 1fr 1fr;
    }
}
@media (min-width: 1025px) {
    .footer {
        font-family: Oswald, sans-serif;
        display: grid;
        background: #212121;
        padding: 0 5vh 5vh 5vh;
    }
    .top {
        display: grid;
        grid-template-rows: 1fr 6fr 1fr;
    }
    .namer {
        font-size: 9vh !important;
        display: grid;
        justify-content: center;
        color: #fc7c33 !important;
        opacity: 0.8;
        align-items: center;
    }
    .connect > div > svg {
        width: 4vw;
    }
    .container {
        display: grid;
        grid-template-rows: 1fr 3fr;
        padding: 2vh;
        grid-row-gap: 5vh;
    }
    .left {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        justify-content: center;
        align-items: start;
    }
    .left > div {
        display: grid;
        grid-template-rows: 1fr 1fr;
        justify-content: center;
        align-items: center;
    }
    .naming {
        font-size: 5vh;
        color: #702ACF;
        display: grid;
        justify-content: center;
        align-items: center;
    }
    .left > div > div > input {
        font-size: 3vh;
        height: 6vh;
        background: #212121;
        color: #5d5d5d;
        border-bottom: 2px solid #5d5d5d;
        border-right: transparent;
    }
    .left > div > div > input:active {
        border: 2px solid #702ACF;
        border-radius: 5vh;
    }
    .left > div > div > input:focus {
        border: 2px #702ACF;
        border-radius: 5vh;
    }
    .right {
        display: grid;
        grid-template-rows: 1fr 5fr;
        justify-content: center;
        align-items: center;
    }
    textarea {
        margin-top: -2vh;
        height:28vh;
        width: 75vw;
        background: #212121;
        border: 2px solid #5d5d5d;
        border-radius: 5vh;
        resize: none;
        padding: 3vh;
        font-size: 3vh;
        color: #5d5d5d;
    }
    .buttonBlock > button {
        font-size: 4vh;
        width: 35vw;
        border: 2px solid #702ACF;
        background: #212121;
        white-space: nowrap;
        color: #702ACF;
        margin-left: 5vh;
        border-radius: 10vh;
        padding: 2vh;
        font-weight: bold;
        /*opacity: 0.49;*/
        box-shadow: #702ACF 0 0 10px;
        cursor: pointer;
        transition: all, 0.5s;
    }
    .buttonBlock > button:hover {
        background: #702ACF;
        color: #212121;
    }
    .buttonBlock {
        display: grid;
        justify-content: center;
        align-items: start;
        height: 5vh;
        margin-top: -4vh;
    }
    .botContainer {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr;
        background: #5d5d5d;
        border-radius: 10vh;
        padding: 3vh;
    }
    .connect {
        display: grid;
        align-items: center;
        grid-template-columns: 2fr 7fr;
    }
    .net {
        font-size: 4vh;
        color: #212121;
        display: grid;
        align-items: center;
    }
    .mail {
        font-size: 4vh;
        color: #212121;
        display: grid;
        align-items: center;
    }
    .mail > a {
        font-size: 4vh;
        text-decoration: underline;
        color: #212121 !important;
        display: grid;
        align-items: center;
    }
    input[type=number]::-webkit-inner-spin-button,
    input[type=number]::-webkit-outer-spin-button {
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        margin: 0;
    }
    /* для элемента input c type="checkbox" */
    .customCheckbox>input {
        position: absolute;
        z-index: -1;
        opacity: 0;
    }

    /* для элемента label, связанного с .custom-checkbox */
    .customCheckbox>span {
        display: inline-flex;
        align-items: center;
        user-select: none;
    }

    /* создание в label псевдоэлемента before со следующими стилями */
    .customCheckbox>span::before {
        content: '';
        display: inline-block;
        width: 1em;
        height: 1em;
        flex-shrink: 0;
        flex-grow: 0;
        border: 1px solid #702ACF;
        border-radius: 0.25em;
        margin-right: 0.5em;
        background-repeat: no-repeat;
        background-position: center center;
        background-size: 50% 50%;
    }

    /* стили при наведении курсора на checkbox */
    .customCheckbox>input:not(:disabled):not(:checked)+span:hover::before {
        border-color: #702ACF;
    }

    /* стили для активного чекбокса (при нажатии на него) */
    .customCheckbox>input:not(:disabled):active+span::before {
        background-color: #702ACF;
        border-color: #702ACF;
    }

    /* стили для чекбокса, находящегося в фокусе */
    .customCheckbox>input:focus+span::before {
        box-shadow: 0 0 0 0.2rem rgba(79, 41, 129, 0.25);
    }

    /* стили для чекбокса, находящегося в фокусе и не находящегося в состоянии checked */
    .customCheckbox>input:focus:not(:checked)+span::before {
        border-color: #702ACF;
    }

    /* стили для чекбокса, находящегося в состоянии checked */
    .customCheckbox>input:checked+span::before {
        border-color: #702ACF;
        background-color: #702ACF;
        background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3e%3c/svg%3e");
    }
    .customCheckbox {
        display: grid;
        padding: 3vh;
        justify-content: end;
    }
    .customCheckbox > span {
        color: #702ACF;
        font-size: 4vh;
    }
    .buttonBlock {
        display: grid;
        grid-template-columns: 1fr 1fr;
    }
}

