@media (max-width: 768px) {
    .container  {
        height: 10vh;
        background: #5d5d5d;
        color: #212121;
        font-size: 4vh;
        font-family: Oswald, sans-serif;
        width: 780vh;
        text-align: center;
        padding-top: 3vh;
        white-space: nowrap;
        margin-top: 0;
    }
    .container > div {
        animation-duration: 20s;
        animation-name: slide;
        animation-iteration-count: infinite;
        animation-timing-function: linear;
    }
    @keyframes slide {
        from {
            margin-left: -15vw;
        }

        to {
            margin-left: -780vw;
        }
    }
}

@media (min-width: 769px) and (max-width: 1024px) {
    .container  {
        height: 10vh;
        background: #5d5d5d;
        color: #212121;
        font-size: 4vh;
        font-family: Oswald, sans-serif;
        width: 340vw;
        text-align: center;
        padding-top: 3vh;
        white-space: nowrap;
        margin-top: -15vh;
    }
    .container > div {
        animation-duration: 15s;
        animation-name: slide;
        animation-iteration-count: infinite;
        animation-timing-function: linear;
    }
    @keyframes slide {
        from {
            margin-left: 1vw;
        }

        to {
            margin-left: -340vw;
        }
    }
}
@media (min-width: 1025px) {
    .container  {
        height: 10vh;
        background: #5d5d5d;
        color: #212121;
        font-size: 4vh;
        font-family: Oswald, sans-serif;
        width: 340%;
        text-align: center;
        padding-top: 3vh;
        white-space: nowrap;
    }
    .container > div {
        animation-duration: 15s;
        animation-name: slide;
        animation-iteration-count: infinite;
        animation-timing-function: linear;
    }
    @keyframes slide {
        from {
            margin-left: 1vw;
        }

        to {
            margin-left: -340vw;
        }
    }
}
