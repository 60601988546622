@import url('https://fonts.googleapis.com/css2?family=Oi&family=Oswald:wght@200..700&display=swap');

@media (max-width: 768px) {
    svg > a {
        fill: #fc7c33;
    }
    li > a, a.name {
        text-decoration: none;
        color: #fc7c33;
    }
.adaptMenu {
    height: 100vh;
    padding: 0;
    transition: all, 0.5s;
}
    .header{
        display: grid;
        font-family: 'Oswald', sans-serif;
        font-size: 3vh;
        background: #212121;
        color: #fc7c33;
        font-weight: bold;
        margin: 0;
        position: fixed;
        animation: header 1s alternate;
        width: 100vw;
        z-index: 9999;
    }
    .left {
        display: grid;
        grid-template-columns: 1fr 3fr 1fr;
    }
    .right {
        display: grid;
        align-items: center;
        padding: 0;
    }
    .logo {
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .name {
        font-size: 4vh;
        display: flex;
        justify-content: left;
        align-items: center;
    }
    .logo > img {
        height: 10vh;
    }
    .right > ul {
        list-style-type: none;
        display: grid;
        grid-template-rows: repeat(5,1fr);
        font-size: 4.5vh;
    }
    .right > ul > li > a {
        width: 100vw;
        text-align: center;
    }

    .right > ul > li {
        width: 100vw;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .right > ul > li > svg {
        height: 7.5vh;
    }
    @keyframes header {
        from{
            margin-top: -15vh;
        }
        to{
            margin-top: 0;
        }
    }
}

@media (min-width: 769px) and (max-width: 1024px) {
    .headerMenu {
        display: none;
    }
    .adaptMenu {
        margin-top: 3vh !important;
    }
    svg > a {
        fill: #fc7c33;
    }
    li > a, a.name {
        text-decoration: none;
        color: #fc7c33;
    }

    .header{
        display: grid;
        grid-template-columns: 1fr 3fr;
        font-family: 'Oswald', sans-serif;
        font-size: 3vh;
        background: #212121;
        color: #fc7c33;
        margin: 0;
        font-weight: bold;
        animation: header 1s alternate;
        position: fixed;
        width: 100vw;
        z-index: 9999;
    }
    .left {
        display: grid;
        grid-template-columns: 1fr 2fr;
    }

    .right {
        display: grid;
        align-items: center;
    }
    .logo {
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .name {
        font-size: 4vh;
        display: flex;
        justify-content: left;
        align-items: center;
    }
    .logo > img {
        height: 10vh;
    }
    .right > ul {
        list-style-type: none;
        display: grid;
        grid-template-columns: repeat(5,1fr);
    }
    .right > ul > li {
        display: inline-flex;
        justify-content: center;
        align-items: center;
    }

    .right > ul > li > svg {
        height: 7.5vh;
    }
    @keyframes header {
        from{
            margin-top: -15vh;
        }
        to{
            margin-top: 0;
        }
    }
}

@media (min-width: 1025px) {
    .headerMenu {
        display: none;
    }
    .adaptMenu {
        margin-top: 3vh !important;
    }
    svg > a {
        fill: #fc7c33;
    }
    li > a, a.name {
        text-decoration: none;
        color: #fc7c33;
    }

    .header{
        display: grid;
        grid-template-columns: 1fr 3fr;
        font-family: 'Oswald', sans-serif;
        font-size: 3vh;
        font-weight: bold;
        background: #212121;
        color: #fc7c33;
        margin: 0;
        animation: header 1s alternate;
        position: fixed;
        width: 100%;
        z-index: 999999;
    }
    .left {
        display: grid;
        grid-template-columns: 1fr 2fr;
    }

    .right {
        display: grid;
        align-items: center;
    }
    .logo {
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .name {
        font-size: 4vh;
        display: flex;
        justify-content: left;
        align-items: center;
    }
    .logo > img {
        height: 10vh;
    }
    .right > ul {
        list-style-type: none;
        display: grid;
        grid-template-columns: repeat(5,1fr);
    }
    .right > ul > li {
        display: inline-flex;
        justify-content: center;
        align-items: center;
    }

    .right > ul > li > svg {
        height: 7.5vh;
    }
    @keyframes header {
        from{
            margin-top: -15vh;
        }
        to{
            margin-top: 0;
        }
    }
}
