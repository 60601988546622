@media (max-width: 768px) {
    .container {
        background: #212121;
        display: grid;
        justify-content: center;
        width: 100vw;
        font-family: Oswald, sans-serif;
    }
    .left {
        display: grid;
        justify-content: center;
        align-items: center;
        order: 2;
    }
    .left > img {
        height: 60vh;
        transition: all, 0.5s;
    }
    .rightCont {
        width: 100%;
        display: grid;
        justify-content: center;
        align-items: center;
    }
    .container > .rightCont >  div {
        order: 1;
        display: block;
        margin-top: 5vh;
        width: 70vw;
        padding: 5vw;
        background: #5d5d5d;
        border-radius: 5vh;
        transition: all, 1s;
    }
    .name {
        color: #4f2981;
        font-size: 5vh;
    }
    .description {
        font-size: 3vh;
        color: #212121;
    }
}
@media (min-width: 769px) and (max-width: 1024px) {
    .container {
        background: #212121;
        display: grid;
        width: 100vw;
        grid-template-columns: 1fr 1fr;
        height: 85vh;
        font-family: Oswald, sans-serif;
    }
    .left {
        display: grid;
        justify-content: center;
        align-items: center;
    }
    .left > img {
        width: 45vw;
        transition: all, 0.5s;
    }
    .left > img:hover {
        transform: rotate(5deg);
    }
    .rightCont {
        justify-content: center;
        align-items: center;
        display: grid;
    }
    .container > .rightCont > div {
        display: block;
        padding: 5vh;
        margin-right: 5vw;
        background: #5d5d5d;
        height: 40%;
        border-radius: 5vh;
        transition: all, 1s;
    }
    .right:hover {
        box-shadow: #702ACF 0 0 10px;
    }
    .name {
        color: #4f2981;
        font-size: 5vh;
    }
    .description {
        font-size: 3vh;
        color: #212121;
    }
}
@media (min-width: 1025px) {
    .container {
        background: #212121;
        display: grid;
        grid-template-columns: 1fr 1fr;
        height: 85vh;
        font-family: Oswald, sans-serif;
        padding: 5vh;
    }
    .left {
        display: grid;
        justify-content: center;
        align-items: center;
    }
    .left > img {
        height: 90vh;
        transition: all, 0.5s;
    }
    .left > img:hover {
        transform: rotate(5deg);
    }
    .rightCont {
        justify-content: center;
        align-items: center;
        display: grid;
    }
    .container > .rightCont > div {
        display: block;
        margin: auto;
        padding: 5vh;
        background: #5d5d5d;
        height: 40%;
        border-radius: 5vh;
        transition: all, 1s;
    }
    .right:hover {
        box-shadow: #702ACF 0 0 10px;
    }
    .name {
        font-size: 5vh;
        color: #4f2981;
    }
    .description {
        font-size: 4vh;
        color: #212121;
    }
}
