@media (max-width: 768px) {
    .container {
        background: #212121;
        display: grid;
        justify-content: center;
        font-family: Oswald, sans-serif;
        width: 100vw;
    }
    .right {
        display: grid;
        justify-content: center;
        width: 100vw;
        padding: 0  !important;
    }
    .right > img {
        height: 60vh;
        transition: all, 0.5s;
    }
    .leftContainer {
        width: 100vw;
        display: grid;
        justify-content: center;
        align-items: center;
    }
    .container > .leftContainer > div {
        margin-top: 5vh;
        display: grid;
        width: 70vw;
        padding: 5vh;
        background: #5d5d5d;
        border-radius: 5vh;
        transition: all, 0.5s;
    }
    .name {
        font-size: 5vh;
        color: #4f2981;
        padding: 0;
        margin: 0;
    }
    .description {
        font-size: 3vh;
        text-align: start;
        color: #212121;
        margin: 0;
        padding: 0;
    }
    .link {
        font-size: 3vh;
        color: #5d5d5d;
        text-decoration: none;
        background: #212121;
        padding: 1vh;
        border-radius: 5vh;
        display: grid;
        height: 5vh;
        margin-top: 5vh;
        margin-left: 6%;
        width: 80%;
        justify-content: center;
        align-items: center;
        border: 2px solid #212121;
        transition: 0.5s;
        font-weight: 500;
    }
    .link:hover {
        background: #5d5d5d;
        color: #212121;
        box-shadow: #212121 0 0 15px;
    }
}
@media (min-width: 769px) and (max-width: 1024px) {
    .container {
        background: #212121;
        display: grid;
        grid-template-columns: 1fr 1fr;
        padding: 5vh;
    }
    .right {
        display: grid;
        justify-content: center;
        align-items: center;
    }
    .right > img {
        height: 90vh;
        transition: all, 0.5s;
    }
    .right > img:hover {
        transform: scale(1.03);
    }
    .leftContainer {
        display: grid;
        justify-content: center;
        align-items: center;
    }
    .container > .leftContainer > div {
        display: block;
        margin: auto;
        padding: 5vh;
        background: #5d5d5d;
        border-radius: 5vh;
        transition: all, 0.5s;
    }
    .left:hover {
        box-shadow: #702ACF 0 0 10px;
    }
    .name {
        font-size: 5vh;
        color: #4f2981;
    }
    .description {
        font-size: 3vh;
        color: #212121;
    }
    .link {
        font-size: 5vh;
        color: #5d5d5d;
        text-decoration: none;
        background: #212121;
        padding: 1vh;
        border-radius: 5vh;
        margin-top: 4vh;
        display: grid;
        justify-content: center;
        align-items: center;
        border: 2px solid #212121;
        transition: 0.5s;
        font-weight: 500;
    }
    .link:hover {
        background: #5d5d5d;
        color: #212121;
        box-shadow: #212121 0 0 15px;
    }
}
@media (min-width: 1025px) {
    .container {
        background: #212121;
        display: grid;
        grid-template-columns: 1fr 1fr;
        height: 85vh;
        font-family: Oswald, sans-serif;
        padding: 5vh;
    }
    .right {
        display: grid;
        justify-content: center;
        align-items: center;
    }
    .right > img {
        height: 90vh;
        transition: all, 0.5s;
    }
    .right > img:hover {
        transform: scale(1.03);
    }
    .leftContainer {
        display: grid;
        justify-content: center;
        align-items: center;
    }
    .container > .leftContainer > div {
        display: block;
        margin: auto;
        padding: 5vh;
        background: #5d5d5d;
        height: 60vh;
        border-radius: 5vh;
        transition: all, 0.5s;
    }
    .left:hover {
        box-shadow: #702ACF 0 0 10px;
    }
    .name {
        font-size: 5vh;
        height: 12vh;
        color: #4f2981;
    }
    .description {
        font-size: 4vh;
        color: #212121;
    }
    .link {
        font-size: 5vh;
        color: #5d5d5d;
        text-decoration: none;
        background: #212121;
        padding: 1vh;
        border-radius: 5vh;
        margin-top: 4vh;
        display: grid;
        width: 25vw;
        justify-content: center;
        align-items: center;
        border: 2px solid #212121;
        transition: 0.5s;
        font-weight: 500;
    }
    .link:hover {
        background: #5d5d5d;
        color: #212121;
        box-shadow: #212121 0 0 15px;
    }
}
