.container {
    background: #212121;
    padding: 5vw;
    color: #5d5d5d;
    font-size: 3.5vh;
}
.name {
    color: #4f2981;
    font-size: 4.5vh;
    text-align: center;
    padding-top: 15vh;
}