@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');

.imageBack{
    width: 100vw !important;
    height: 100vh;
    background-image: url(../../images/water.webp);
    background-repeat: no-repeat;
    background-size: cover;
    cursor: none;
    background-position: center 80%;
    overflow: hidden;
}
.fish {
    position: absolute;
    top: 20vh;
    right: -12vw;
    width: 30vw;
    transform: scaleX(-1);
    rotate: -24deg;
}
.coral {
    position: absolute;
    width: 40vw;
    rotate: 45deg;
    top: 60vh;
    left: -10vw;
}
.coral2 {
    position: absolute;
    width: 60vw;
    rotate: -65deg;
    top: 60vh;
    right: -20vw;
}
.fishContainer {
    display: flex;
    height: 100vh;
    width: 100vw;
    overflow: hidden;
}
.title {
    position: absolute;
    font-family: "Montserrat", serif !important;
    color: #fc7c33;
    font-weight: 900;
    top: 18vh;
    width: 60vw;
    left: 20vw;
    text-align: center;
    font-size: 12vh;
    mix-blend-mode: screen;
    border-radius: 10px;
    text-shadow: 0 0 15px;
    /* Тень */
}
.water {
    color: #702ACF;
    font-size: 8vw;
    margin: 0;
    padding: 0;
}
.buttonLega1 {
    width: 35vw;
    border-radius: 10vh;
    height: 10vh;
    color: #4f2981;
    font-weight: bold;
    font-size: 4vh;
    margin: auto;
    background: #212121;
    border: 2px solid #4f2981;
    box-shadow: 0 0 10px #4f2981;
    transition: 1s;
    cursor: pointer;
}
.buttonLega1:hover {
    background: #4f2981;
    color: #212121;
}