@media (max-width: 768px) {
    .container {
        display: grid;
        grid-row-gap: 0;
        transition: all, 1s;
    }
    .quest {
        display: grid;
        grid-template-columns: 9fr 1fr;
        background: #5d5d5d;
        margin: 1vh auto;
        width: 85vw;
        padding: 5vw;
        border-radius: 10vh;
        color: #212121;
        font-size: 2.8vh !important;
        transition: all, 1s;
    }
    .quest > h2 {
        font-size: 2.8vh !important;
    }
    .button {
        display: grid;
        background: #212121;
        width: 60px;
        height: 60px;
        margin: auto;
        border-radius: 50%;
        align-items: center;
        justify-content: center;
        transition: all, 1s;
        cursor: pointer;
    }
    .button:hover {
        background: #4f2981;
        rotate: 180deg;
    }
    .button > svg {
        width: 8vw;
    }
    .answer {
        display: grid;
        width: 113%;
        padding: 3vw;
        color: #5d5d5d;
        background: #212121;
        border-radius: 5vh;
        font-size: 2.3vh;
    }
}
@media (min-width: 769px) and (max-width: 1024px) {
    .container {
        display: grid;
        grid-row-gap: 0;
        transition: all, 1s;
    }
    .quest {
        display: grid;
        grid-template-columns: 9fr 1fr;
        background: #5d5d5d;
        width: 80vw;
        margin-left: 3vw;
        padding: 2vw;
        border-radius: 10vh;
        color: #212121;
        font-size: 3.5vh !important;
        transition: all, 1s;
        margin-top: 2vh;
    }
    .quest > h2 {
        font-size: 3.5vh !important;
    }
    .button {
        display: grid;
        background: #212121;
        width: 40px;
        height: 40px;
        border-radius: 50%;
        margin: auto;
        align-items: center;
        justify-content: center;
        transition: all, 1s;
        cursor: pointer;
    }
    .button:hover {
        background: #4f2981;
        rotate: 180deg;
    }
    .button > svg {
        width: 2.5vw;
    }
    .answer {
        display: grid;
        padding: 1vw;
        color: #5d5d5d;
        background: #212121;
        border-radius: 5vh;
        font-size: 4vh;
    }
}
@media (min-width: 1025px) {
    .container {
        display: grid;
        grid-row-gap: 0;
        transition: all, 1s;
    }
    .quest {
        display: grid;
        grid-template-columns: 9fr 1fr;
        background: #5d5d5d;
        width: 80vw;
        margin-left: 3vw;
        padding: 2vw;
        border-radius: 10vh;
        color: #212121;
        font-size: 3.5vh !important;
        transition: all, 1s;
        margin-top: 2vh;
        height: 8vh;
    }
    .quest > h2 {
        font-size: 3.5vh !important;
    }
    .button {
        display: grid;
        background: #212121;
        width: 60px;
        height: 60px;
        margin: auto;
        border-radius: 50%;
        align-items: center;
        justify-content: center;
        transition: all, 1s;
        cursor: pointer;
    }
    .button:hover {
        background: #4f2981;
        rotate: 180deg;
    }
    .button > svg {
        width: 2.5vw;
    }
    .answer {
        display: grid;
        padding: 1vw;
        color: #5d5d5d;
        background: #212121;
        border-radius: 5vh;
        font-size: 4vh;
    }
}
