@import url('https://fonts.googleapis.com/css2?family=Moderustic:wght@300..800&display=swap');
.titles {
    position: absolute;
    width: 60vw;
    top: 30vh;
    color: #4f2981;
    font-weight: bolder;
    left: 20vw;
}
.title {
    font-size: 7vw;
    font-family: 'Moderustic', sans-serif !important;
    font-weight: bolder;
    mix-blend-mode: multiply;
    text-align: center;
    text-shadow: 0 0 15px;
}
.subTitle {
    font-size: 2vw;
    text-align: center;
    color: #212121;
    font-family: 'Moderustic', sans-serif !important;
}
.honey1, .honey2, .honey3, .honey4 {
    position: absolute;
    width: 40vw;
}
.honey1, .honey2 {
    top: -30vh;
}
.honey3, .honey4 {
    top: 55vh;
}
.honey1 {
    left: -14vw;
    rotate: -20deg;
}
.honey2 {
 right: -20vw;
    width: 45vw;
    rotate: 150deg;
}
.honey3 {
 left: -20vw;
    width: 50vw;
    rotate: 15deg;
}
.honey4 {
 right: -10vw;
}
.buttonLega {
        width: 35vw;
        border-radius: 10vh;
        height: 10vh;
        color: #faa330;
        font-weight: bold;
        font-size: 4vh;
        margin: auto;
        background: #471c15;
        border: 2px solid #faa330;
        box-shadow: 0 0 10px #faa330;
        transition: 1s;
        cursor: pointer;
    }
    .buttonLega:hover {
        background: #faa330;
        color: #4f2200;
    }