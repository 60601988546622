@media (max-width: 768px) {
    .mainContainer {
        display: grid;
        background: #212121;
        width: 100vw;
    }
    .serSector {
        display: grid;
        width: 100vw;
    }
    .serviceContainer {
        width: 100vw;
        display: grid;
        justify-content: center;
        align-items: center;
        grid-row-gap: 3vh;
    }
    .nameSector {
        font-size: 6vh;
        color: #702ACF;
        opacity: 0.7;
        padding: 4vw;
    }
    .nameKeer {
        font-size: 8vh;
        color: #fc7c33;
        opacity: 0.7;
        white-space: nowrap;
        padding: 4vw;
        text-align: center;
    }
    .serviceBlock {
        width: 60vw;
        margin: 0 !important;
        background: #5d5d5d;
        border-radius: 5vh;
        padding: 5vh;
        transition: all, 1s;
    }
    .serviceName {
        font-size: 5vh;
        color: #4f2981;
    }
    .serviceDescription {
        font-size: 3vh;
        color: #212121;
    }
    .serviceCost {
        font-size: 5vh !important;
        opacity: 0.8;
        color: #fc7c33;
        white-space: nowrap;
    }
    .keySector{
        display: grid;
        justify-content: center;
        align-items: center;
    }
    .key {
        display: grid;
        grid-template-rows: 1fr 8fr 1fr;
        background: #5d5d5d;
        margin: auto;
        border-radius: 5vh;
        padding: 5vh;
        width: 70vw;
        transition: all, 0.5s;
    }
    .key:hover {
        box-shadow: inset #fc7c33 0 0 10px;
    }
    .keyContainer {
        display: grid;
        grid-row-gap: 3vh;
        padding-top: 5vh;
    }
    .name {
        color: #4f2981 !important;
        font-size: 4vh !important;
        padding: 0 !important;
        opacity: 1 !important;
        margin: 0 !important;
        height: 6vh !important;
        justify-content: start !important;
        display: grid;
    }
    .indicator {
        display: inline-block;
    }
    .cont {
        display: grid;
        grid-template-columns: 1fr 9fr;
    }
    .serviceCost2 {
        font-size: 5vh;
        opacity: 0.8;
        color: #fc7c33;
    }
}
@media (min-width: 769px) and (max-width: 1024px) {
    .mainContainer {
        display: grid;
        background: #212121;
    }
    .nameSector {
        font-size: 10vh;
        padding-left: 5vw;
        color: #702ACF;
        opacity: 0.7;
    }
    .nameKeer {
        font-size: 11vh;
        color: #fc7c33;
        opacity: 0.8;
        text-align: center;
    }
    .serviceBlock {
        height: 20vh;
        background: #5d5d5d;
        display: grid;
        grid-template-columns: 1fr 4fr 1fr;
        grid-column-gap: 2vw;
        margin: 3vh;
        width: 90vw;
        border-radius: 5vh;
        padding: 5vh;
        transition: all, 1s;
    }
    .serviceBlock:hover {
        box-shadow: inset #702ACF 0 0 10px;
    }
    .serviceName {
        font-size: 5vh;
        color: #4f2981;
    }
    .serviceDescription {
        font-size: 3vh;
        color: #212121;
        display: grid;
        align-items: center;
        justify-content: center;
    }
    .serviceCost {
        font-size: 5vh;
        opacity: 0.8;
        color: #fc7c33;
        padding: 4vh;
        white-space: nowrap;
    }
    .key {
        display: grid;
        grid-template-rows: 1fr 8fr 1fr;
        background: #5d5d5d;
        border-radius: 5vh;
        margin: auto;
        padding: 5vh;
        width: 25vw;
        transition: all, 0.5s;
    }
    .key:hover {
        box-shadow: inset #fc7c33 0 0 10px;
    }
    .keyContainer {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        justify-content: space-between;
        padding-top: 5vh;
    }
    .name {
        color: #4f2981;
        font-size: 4vh;
    }
    .indicator {
        display: inline-block;
    }
    .cont {
        display: grid;
        grid-template-columns: 1fr 9fr;
    }
    .serviceCost2 {
        font-size: 5vh;
        opacity: 0.8;
        color: #fc7c33;
    }
}
@media (min-width: 1025px) {
    .mainContainer {
        display: grid;
        background: #212121;
    }
    .nameSector {
        font-size: 8vh;
        padding-left: 5vh;
        color: #702ACF;
        opacity: 0.7;
    }
    .nameKeer {
        font-size: 11vh;
        color: #fc7c33;
        opacity: 0.8;
        text-align: center;
    }
    .serviceContainer {
        display: grid;
        justify-items: center;
    }
    .serviceBlock {
        height: 20vh;
        background: #5d5d5d;
        display: grid;
        grid-template-columns: 1fr 4fr 1fr;
        grid-column-gap: 2vw;
        margin: 2vh 0;
        width: 85vw;
        border-radius: 5vh;
        padding: 5vh;
        transition: all, 1s;
    }
    .serviceBlock:hover {
        box-shadow: inset #702ACF 0 0 10px;
    }
    .serviceName {
        font-size: 5vh;
        color: #4f2981;
    }
    .serviceDescription {
        font-size: 3vh;
        color: #212121;
        display: grid;
        align-items: center;
        justify-content: center;
    }
    .serviceCost {
        font-size: 5vh;
        opacity: 0.8;
        color: #fc7c33;
        padding: 4vh;
        white-space: nowrap;
    }
    .keySector{
        padding: 5vh;
    }
    .key {
        display: grid;
        grid-template-rows: 1fr 8fr 1fr;
        background: #5d5d5d;
        margin-left: auto;
        margin-right: auto;
        border-radius: 5vh;
        padding: 5vh;
        width: 24vw;
        transition: all, 0.5s;
    }
    .key:hover {
        box-shadow: inset #fc7c33 0 0 10px;
    }
    .keyContainer {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        justify-content: space-around;
        padding-top: 5vh;
    }
    .name {
        color: #4f2981;
        font-size: 4vh;
    }
    .indicator {
        display: inline-block;
    }
    .cont {
        display: grid;
        grid-template-columns: 1fr 9fr;
    }
    .serviceCost2 {
        font-size: 5vh;
        opacity: 0.8;
        color: #fc7c33;
    }
}

