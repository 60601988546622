@media (max-width: 768px) {
    .container {
        background: #212121;
    }
    .name {
        font-size: 6vh;
        text-align: center;
        color: #702ACF;
        opacity: 0.7;
    }
    .container > div:last-child {
        display: grid;
        background: #5d5d5d;
        border-radius: 5vh;
        transition: all, 1s;
        margin: 10vw
    }
    .mainTech {
        display: grid;
        justify-content: center;
        align-items: center;
        grid-row-gap: 2vh;
        padding: 2vh;
    }
    .mainTech > div:first-child {
        margin-top: 5vh;
    }
    .mainTech > div {
        background: #212121;
        width: 75%;
        padding: 3vh;
        margin: auto;
        border-radius: 5vh;
        transition: all, 0.5s;
    }
    .mainTech > div:hover {
        box-shadow: #fc7c33 0 0 10px;
    }
    .head {
        font-size: 4vh;
        color: #fc7c33;
        opacity: 0.7;
        border-bottom: 2px solid #5d5d5d;
        padding-bottom: 2vh;
    }
    .list {
        color: #5d5d5d;
        font-size: 2.5vh;
    }
    .someTech {
        padding: 3vh;
        font-size: 3vh;
        color: #212121;
    }
    .someTech > div {
        font-size: 4vh;
    }
}
@media (min-width: 769px) and (max-width: 1024px) {
    .container {
        background: #212121;
    }
    .name {
        font-size: 10vh;
        color: #702ACF;
        opacity: 0.7;
        display: grid;
        justify-content: center;
    }
    .container > div:last-child {
        display: grid;
        grid-template-rows: 4fr 1fr;
        background: #5d5d5d;
        padding: 3vh;
        margin: 5vh;
        border-radius: 5vh;
        transition: all, 1s;
    }
    .mainTech {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        grid-column-gap: 3vw;
        justify-content: center;
    }
    .mainTech > div {
        background: #212121;
        width: 22vw;
        padding: 3vh;
        border-radius: 5vh;
        transition: all, 0.5s;
    }
    .mainTech > div:hover {
        box-shadow: #fc7c33 0 0 10px;
    }
    .head {
        font-size: 4vh;
        color: #fc7c33;
        opacity: 0.7;
        border-bottom: 2px solid #5d5d5d;
        padding-bottom: 2vh;
    }
    .list {
        color: #5d5d5d;
        font-size: 2.5vh;
    }
    .someTech {
        padding: 1vh;
        font-size: 3vh;
        color: #212121;
    }
    .someTech > div {
        font-size: 4vh;
    }
}
@media (min-width: 1025px) {
    .container {
        background: #212121;
    }
    .name {
        font-size: 10vh;
        color: #702ACF;
        opacity: 0.7;
        display: grid;
        justify-content: center;
    }
    .container > div:last-child {
        display: grid;
        grid-template-rows: 4fr 1fr;
        background: #5d5d5d;
        padding: 3vh;
        margin: 5vh;
        border-radius: 5vh;
        transition: all, 1s;
    }
    .mainTech {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        justify-content: space-around;
    }
    .mainTech > div {
        background: #212121;
        width: 22vw;
        padding: 3vh;
        margin: auto;
        height: 90%;
        border-radius: 5vh;
        transition: all, 0.5s;
    }
    .mainTech > div:hover {
        box-shadow: #fc7c33 0 0 10px;
    }
    .head {
        font-size: 4vh;
        color: #fc7c33;
        opacity: 0.7;
        border-bottom: 2px solid #5d5d5d;
        padding-bottom: 2vh;
    }
    .list {
        color: #5d5d5d;
        font-size: 2.5vh;
    }
    .someTech {
        padding: 1vh;
        font-size: 3vh;
        color: #212121;
    }
    .someTech > div {
        font-size: 4vh;
    }
}

