
.faqItem {
    background: #212121;
    border-radius: 5vh;
    overflow: hidden;
    width: 60vw;
    margin: 2vh auto;
    padding: 2vh;

}

.faqQuestion {
    background: #212121;
    color: #5d5d5d;
    font-size: 2vh;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.faqQuestion > button {
    background: #5d5d5d;
    border: none;
    color: #212121;
    border-radius: 50%;
    width: 75px !important;
    height: 75px !important;
    padding: 3px 0 0 0;
    cursor: pointer;
    transition: 0.75s;
}
.faqQuestion > button:hover {
    background: #4f2981;
    color: #212121;
    box-shadow: 0 0 10px #4f2981;
}
.faqQuestion > button > div {
    margin: auto;
    font-size: 45px;
    width: 75px !important;
    height: 75px !important;
}

.faqAnswer {
    background-color: #212121;
    color: #5d5d5d;
    font-size: 2vh;
    transition: max-height 0.3s ease;
}
