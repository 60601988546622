@media (max-width: 768px) {

    .container {
        background: #212121;
        color: #4f2981;
        font-size: 14vw;
        font-weight: bolder;
        justify-content: center;
        align-items: center;
        display: flex;
        height: 70vh;
    }
    .container > div {
        padding: 5vh;
        border-radius: 5vh;
        animation-name: begin1;
        animation-duration: 2s;
    }
    .name > span {
        animation: glow1 4s ease infinite;
    }

    @keyframes glow1 {
        0%, 100% {
            color: #702ACF;
            text-shadow: 0 0 10px #4f2981, 0 0 50px #4f2981, 0 0 100px #4f2981;
        }

        25%, 75% {
            color: #4f2981;
            text-shadow: none;
        }
    }

    .name > span:nth-child(2) {
        animation-delay: 0.25s;
    }

    .name > span:nth-child(3) {
        animation-delay: 0.5s;
    }

    .name > span:nth-child(4) {
        animation-delay: 0.75s;
    }

    .name > span:nth-child(5) {
        animation-delay: 1s;
    }

    .name > span:nth-child(6) {
        animation-delay: 1.25s;
    }

    .name > span:nth-child(7) {
        animation-delay: 1.5s;
    }
    .name > span:nth-child(8) {
        animation-delay: 1.75s;
    }
    .name > span:nth-child(9) {
        animation-delay: 2s;
    }
    .name > span:nth-child(10) {
        animation-delay: 2.25s;
    }
    .name > span:nth-child(11) {
        animation-delay: 2.5s;
    }
    .name > span:nth-child(12) {
        animation-delay: 2.75s;
    }
    .name > span:nth-child(13) {
        animation-delay: 3s;
    }
    .name > span:nth-child(14) {
        animation-delay: 3.25s;
    }
    .name > span:nth-child(15) {
        animation-delay: 3.5s;
    }
    .name > span:nth-child(16) {
        animation-delay: 3.75s;
    }


    @keyframes begin1 {
        from {
            opacity: 0.1;
            margin-top: -100%;
        }

        to {
            opacity: 1;
        }
    }

}
@media (min-width: 769px) and (max-width: 1024px) {
    .container {
        background: #212121;
        white-space: nowrap;
        color: #4f2981;
        font-size: 12vh;
        font-weight: bolder;
        justify-content: center;
        align-items: center;
        display: flex;
        padding: 10vh;
        height: 70vh;
    }
    .container > div {
        padding: 5vh;
        border-radius: 5vh;
        animation-name: begin;
        animation-duration: 2s;
    }
    .name > span {
        animation: glow 4s ease infinite;
    }

    @keyframes glow {
        0%, 100% {
            color: #702ACF;
            text-shadow: 0 0 10px #4f2981, 0 0 50px #4f2981, 0 0 100px #4f2981;
        }

        25%, 75% {
            color: #4f2981;
            text-shadow: none;
        }
    }

    .name > span:nth-child(2) {
        animation-delay: 0.25s;
    }

    .name > span:nth-child(3) {
        animation-delay: 0.5s;
    }

    .name > span:nth-child(4) {
        animation-delay: 0.75s;
    }

    .name > span:nth-child(5) {
        animation-delay: 1s;
    }

    .name > span:nth-child(6) {
        animation-delay: 1.25s;
    }

    .name > span:nth-child(7) {
        animation-delay: 1.5s;
    }
    .name > span:nth-child(8) {
        animation-delay: 1.75s;
    }
    .name > span:nth-child(9) {
        animation-delay: 2s;
    }
    .name > span:nth-child(10) {
        animation-delay: 2.25s;
    }
    .name > span:nth-child(11) {
        animation-delay: 2.5s;
    }
    .name > span:nth-child(12) {
        animation-delay: 2.75s;
    }
    .name > span:nth-child(13) {
        animation-delay: 3s;
    }
    .name > span:nth-child(14) {
        animation-delay: 3.25s;
    }
    .name > span:nth-child(15) {
        animation-delay: 3.5s;
    }
    .name > span:nth-child(16) {
        animation-delay: 3.75s;
    }


    @keyframes begin {
        from {
            font-size: 10vh;
            opacity: 0.1;
            margin-top: -100vh
        }

        to {
            font-size: 12vh;
            opacity: 1;
        }
    }
}
@media (min-width: 1025px) {

    .container {
        background: #212121;
        color: #4f2981;
        font-size: 14vh;
        font-weight: bolder;
        white-space: nowrap;
        justify-content: center;
        align-items: center;
        display: flex;
        padding: 10vh;
        height: 70vh;
    }
    .container > div {
        padding: 5vh;
        border-radius: 5vh;
        animation-name: begin;
        animation-duration: 2s;
    }
    .name > span {
        animation: glow 4s ease infinite;
    }

    @keyframes glow {
        0%, 100% {
            color: #702ACF;
            text-shadow: 0 0 10px #4f2981, 0 0 50px #4f2981, 0 0 100px #4f2981;
        }

        25%, 75% {
            color: #4f2981;
            text-shadow: none;
        }
    }

    .name > span:nth-child(2) {
        animation-delay: 0.25s;
    }

    .name > span:nth-child(3) {
        animation-delay: 0.5s;
    }

    .name > span:nth-child(4) {
        animation-delay: 0.75s;
    }

    .name > span:nth-child(5) {
        animation-delay: 1s;
    }

    .name > span:nth-child(6) {
        animation-delay: 1.25s;
    }

    .name > span:nth-child(7) {
        animation-delay: 1.5s;
    }
    .name > span:nth-child(8) {
        animation-delay: 1.75s;
    }
    .name > span:nth-child(9) {
        animation-delay: 2s;
    }
    .name > span:nth-child(10) {
        animation-delay: 2.25s;
    }
    .name > span:nth-child(11) {
        animation-delay: 2.5s;
    }
    .name > span:nth-child(12) {
        animation-delay: 2.75s;
    }
    .name > span:nth-child(13) {
        animation-delay: 3s;
    }
    .name > span:nth-child(14) {
        animation-delay: 3.25s;
    }
    .name > span:nth-child(15) {
        animation-delay: 3.5s;
    }
    .name > span:nth-child(16) {
        animation-delay: 3.75s;
    }


    @keyframes begin {
        from {
            font-size: 10vh;
            opacity: 0.1;
            margin-top: -100vh
        }

        to {
            font-size: 14vh;
            opacity: 1;
        }
    }

}
