@media (max-width: 768px) {
    .container {
        display: grid;
        background: #212121;
        grid-row-gap: 2vh;
    }
    .container > div {
        display: grid;
        width: 70vw;
        margin: auto;
        background: #5d5d5d;
        border-radius: 5vh;
        padding: 5vh;
        transition: all, 1s;
    }
    .container > div:hover {
        box-shadow: #702ACF 0 0 10px;
    }
    .naming {
        font-size: 3vh;
        display: grid;
        grid-template-columns: 4fr 1fr;
        grid-column-gap: 3vw;
    }
    .description {
        font-size: 3vh;
        color: #212121;
    }
    .number {
        color: #fc7c33;
        opacity: 0.8;
    }
    .name {
        color: #4f2981;
    }
}
@media (min-width: 769px) and (max-width: 1024px) {
    .container {
        display: grid;
        background: #212121;
        grid-template-columns: repeat(4, 1fr);
        grid-column-gap: 2vh;
        grid-row-gap: 1vw;
        padding: 2vh;
    }
    .container > div {
        display: grid;
        background: #5d5d5d;
        border-radius: 5vh;
        padding: 5vh;
        transition: all, 1s;
    }
    .container > div:hover {
        box-shadow: #702ACF 0 0 10px;
    }
    .naming {
        display: grid;
        font-size: 3vh;
        grid-column-gap: 2vw;
        grid-template-columns: 4fr 1fr;
    }
    .description {
        font-size: 3vh;
        color: #212121;
    }
    .number {
        color: #fc7c33;
        opacity: 0.8;
        font-size: 4vh;
    }
    .name {
        color: #4f2981;
    }
}
@media (min-width: 1025px) {

    .container {
        display: grid;
        background: #212121;
        grid-template-columns: repeat(4, 1fr);
        grid-column-gap: 2vh;
        grid-row-gap: 1vw;
        padding: 2vh;
    }
    .container > div {
        display: grid;
        background: #5d5d5d;
        border-radius: 5vh;
        padding: 5vh;
        transition: all, 1s;
    }
    .container > div:hover {
        box-shadow: #702ACF 0 0 10px;
    }
    .naming {
        display: grid;
        font-size: 3vh;
        grid-column-gap: 2vw;
        grid-template-columns: 4fr 1fr;
    }
    .description {
        font-size: 3vh;
        color: #212121;
    }
    .number {
        color: #fc7c33;
        opacity: 0.8;
        font-size: 4vh;
    }
    .name {
        color: #4f2981;
    }
}
