@media (max-width: 768px) {
    .container {
        display: grid;
        background: #212121;
        justify-content: center;
        align-items: center;
        padding-top: 10vh;
    }
    .right {
        display: grid;
        justify-content: center;
        align-items: center;
    }
    .right > img {
        width: 80vw;
        transition: all, 1s;
    }
    .container > .leftCont {
        padding: 10vw;
        display: grid;
        justify-content: center;
        align-items: center;
    }
    .container > .leftCont > div {
        background: #5d5d5d;
        border-radius: 5vh;
        padding: 5vh;
        font-size: 3vh;
        color: #212121;
        display: block;
        transition: all, 1s;
    }
    .container > .leftCont > div:hover {
        box-shadow: #702ACF 0 0 10px;
    }
    .container > .leftCont > div > div {
        display: inline-block;
        color: #4f2981;
    }
}
@media (min-width: 769px) and (max-width: 1024px) {
    .container {
        display: grid;
        background: #212121;
        grid-template-columns: 1fr 1fr;
        padding: 5vh;
    }
    .right > img {
        width: 35vw;
        transition: all, 1s;
    }
    .right > img:hover {
        rotate: -15deg;
    }
    .container > .leftCont {
        display: grid;
        justify-content: center;
        align-items: center;
    }
    .container > .leftCont > div {
        background: #5d5d5d;
        border-radius: 5vh;
        padding: 5vh;
        font-size: 4vh;
        color: #212121;
        display: block;
        margin: auto;
        transition: all, 1s;
    }
    .container > .leftCont > div:hover {
        box-shadow: #702ACF 0 0 10px;
    }
    .container > .leftCont > div > div {
        display: inline-block;
        color: #4f2981;
    }
}
@media (min-width: 1025px) {

    .container {
        display: grid;
        background: #212121;
        grid-template-columns: 1fr 1fr;
        padding: 5vh;
    }
    .right > img {
        width: 35vw;
        transition: all, 1s;
    }
    .right > img:hover {
        rotate: -15deg;
    }
    .container > .leftCont > div {
        background: #5d5d5d;
        border-radius: 5vh;
        padding: 5vh;
        font-size: 4vh;
        color: #212121;
        height: 40vh;
        display: block;
        margin: auto auto auto -15vh;
        transition: all, 1s;
    }
    .container > .leftCont > div:hover {
        box-shadow: #702ACF 0 0 10px;
    }
    .container > .leftCont > div > div {
        display: inline-block;
        color: #4f2981;
    }
}
