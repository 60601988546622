@media (max-width: 768px) {
    .container {
        display: flex;
        justify-content: center;
        align-items: center;
        color: #702ACF;
        background: #212121;
        font-size: 8vh;
        height: 14vh;
        margin: 0;
        padding-top: 2vh;
    }
    .container > div {
        opacity: 0.7;
    }
}
@media (min-width: 769px) and (max-width: 1024px) {
    .container {
        display: flex;
        justify-content: center;
        align-items: center;
        color: #702ACF;
        background: #212121;
        font-size: 10vh;
        margin: 0;
        height: 14vh;
        padding-top: 2vh;
    }
    .container > div {
        opacity: 0.7;
    }
}
@media (min-width: 1025px) {
    .container {
        display: flex;
        justify-content: center;
        align-items: center;
        color: #702ACF;
        background: #212121;
        font-size: 12vh;
        margin: 0;
        height: 14vh;
        padding-top: 2vh;
    }
    .container > div {
        opacity: 0.7;
    }
}


