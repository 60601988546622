@media (max-width: 768px) {
    .container {
        margin: 0;
        display: grid;
        background-position: center;
        height: 140vh;
        margin-top: -140vh;
    }
    .right > img {
        height: 50vh;
        transition: all, 0.5s;
        animation: image 1s ease;
        z-index: 28;
    }
    .right > img:hover {
        rotate: -5deg;
    }
    .right {
        display: grid;
        justify-content: center;
        align-items: center;
    }
    .left {
        display: grid;
        font-size: 6vh;
        padding-top: 10vh;
        font-weight: bolder;
        color: #4f2981;
        justify-content: center;
        align-items: center;
        animation: txt 1s ease;
        z-index: 28;
    }
}
@media (min-width: 769px) and (max-width: 1024px) {

    .container {
        margin-top: -100vh;
        display: grid;
        grid-template-columns: 1fr 1fr;
        height: 100vh;
    }
    .right > img {
        width: 40vw;
        margin-top: 6vh;
        transition: all, 0.5s;
        animation: image 1s ease;
        z-index: 28;
    }
    .right > img:hover {
        rotate: -5deg;
    }
    .right {
        display: grid;
        justify-content: center;
        align-items: center;
    }
    .left {
        display: grid;
        margin: auto;
        height: 55vh;
        font-size: 6vw;
        font-weight: bolder;
        color: #4f2981;
        justify-content: center;
        align-items: center;
        animation: txt 1s ease;
        z-index: 28;
    }
    .main {
        font-size: 6.1vh;
        color: #702ACF;
        font-weight: bold;
    }
    .subName {
        font-size: 3vh;
        color: #5d5d5d;
        padding-top: 3vh;
    }
}
@media (min-width: 1025px) {
    .container {
        margin: 0;
        padding: 10vh;
        height: 100vh;
        margin-top: -100vh;
        display: grid;
        grid-template-columns: 1fr 1fr
    }
    .right > img {
        width: 40vw;
        margin-top: 6vh;
        transition: all, 0.5s;
        animation: image 1s ease;
        z-index: 28;
    }
    .right > img:hover {
        rotate: -5deg;
    }
    .right {
        display: grid;
        justify-content: center;
        align-items: center;
    }
    .left {
        display: grid;
        height: 55vh;
        z-index: 28;
        font-size: 10vh;
        font-weight: bolder;
        color: #4f2981;
        text-align: center;
        align-items: center;
        padding-top: 15vh;
        margin-top: -40vh;
        animation: txt 1s ease;
    }
}
