/*****************************COLORS****************************************/
/*
#4f2981  темно-фиолетовый
#702ACF  светло-фиол
#212121  темно-серый
#5d5d5d  светло-серый
#fc7c33  оранжевый
*/
@import url('https://fonts.googleapis.com/css2?family=Jost:ital,wght@0,100..900;1,100..900&display=swap');

* {
    font-family: "Jost", serif;
}
h1, h2, h3, h4, p{
    margin: 0;
}
@media (min-width: 1025px) {
    *::-webkit-scrollbar {
        width: 12px;               /* ширина scrollbar */
    }
    *::-webkit-scrollbar-track {
        background: #5d5d5d;        /* цвет дорожки */
    }
    *::-webkit-scrollbar-thumb {
        background-color: #4f2981;    /* цвет плашки */
        border-radius: 10px;       /* закругления плашки */ /* padding вокруг плашки */
    }
}
body {
    margin: 0;
    padding: 0;
    overflow-x: hidden !important;
    cursor: default;
    font-family: "Jost", serif;
}
*::selection {
    color: #fc7c33;
    background-color: #4f2981;
}
.alert-enter {
    opacity: 0;
    margin-top: -27vh;
}
.alert-enter-active {
    opacity: 1;
    margin-top: 0;
    transition: all, 1000ms;
}
.alert-exit {
    opacity: 1;
}
.alert-exit-active {
    opacity: 0;
    display: flow;
    margin-top: -27vh;
    transition: all, 1000ms;
}
