@media (max-width: 768px) {
    .container {
        display: grid;
        background: #212121;
        justify-content: center;
    }
    .left {
        order: 1;
        display: grid;
    }
    .left > img {
        height: 60vh;
        margin: auto;
        transition: all, 1s;
    }
    .rightCont {
        display: grid;
        justify-content: center;
        order:2;
    }
    .container > .rightCont  > div {
        display: block;
        margin: auto;
        width: 80vw;
        padding: 3vh;
        background: #5d5d5d;
        color: #212121;
        border-radius: 5vh;
        font-size: 4vh;
        transition: all, 1s;
    }
    .container > .rightCont  > div > div {
        display: inline-block;
        color: #4f2981;
    }
}
@media (min-width: 769px) and (max-width: 1024px) {
    .container {
        display: grid;
        grid-template-columns: 1fr 1fr;
        background: #212121;
        padding: 5vw;
    }
    .left > img {
        width: 40vw;
        margin: auto;
        transition: all, 1s;
    }
    .left > img:hover {
        rotate: 5deg;
    }
.left {
    display: grid;
    align-items: center;
}
    .container > .rightCont  > div {
        display: block;
        background: #5d5d5d;
        color: #212121;
        padding: 5vh;
        border-radius: 5vh;
        font-size: 5vh;
        margin-top: 10vh;
        transition: all, 1s;
    }
    .container > .rightCont  > div > div {
        display: inline-block;
        color: #4f2981;
    }
    .container > .rightCont  > div:hover {
        box-shadow: #702ACF 0 0 15px;
    }
}
@media (min-width: 1025px) {

    .container {
        display: grid;
        grid-template-columns: 1fr 1fr;
        background: #212121;
        padding: 5vw;
    }
    .left > img {
        width: 40vw;
        transition: all, 1s;
    }
    .left > img:hover {
        rotate: 5deg;
    }

    .container > .rightCont  > div {
        display: block;
        background: #5d5d5d;
        color: #212121;
        padding: 5vh;
        border-radius: 5vh;
        height: 50vh;
        font-size: 5vh;
        margin-top: 10vh;
        transition: all, 1s;
    }
    .container > .rightCont  > div > div {
        display: inline-block;
        color: #4f2981;
    }
    .container > .rightCont  > div:hover {
        box-shadow: #702ACF 0 0 15px;
    }

}
