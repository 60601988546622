@media (max-width: 768px) {
    .process {
        display: grid;
    }
    .leftProc {
        display: grid;
        height: 30vh;
        width: 90vw;
    }
    .leftProc > div {
        grid-template-columns: repeat(3,30vw);
        grid-column-gap: 2vw;
        grid-template-rows: 1fr 1fr 1fr;
        display: grid;
        text-align: center;
        color: #212121;
        border-radius: 5vh !important;
        font-size: 1.8vh;
        padding: 1vh;
    }
    .leftProc > div > div:last-child{
        width: 40vw;
    }
    .leftProc > div > div:hover {
        color: #4f2981;
        background: #212121;
        cursor: pointer;
    }
    .leftProc > div > div {
        border-bottom: 2px solid #212121;
        justify-content: center;
        align-items: center;
        border-radius: 5vh;
        display: grid;
        transition: 0.5s !important;
        width: 30vw;
        height: 8vh;
    }
    .rightProc {
        margin: auto;
        padding: 5vh 0;
    }
    .item {
        grid-template-rows: 30vh 30vh;
        width: 95vw;
        height: 60vh;
        background: #5d5d5d;
        border-radius: 4vh;
        overflow: hidden;
        margin: auto;
        transition: 1s;
    }
    .item > div > img {
        width: 95vw;
        margin: auto;
    }
    .item > div:first-child {
        width: 95vw;
        height: 30vh;
        overflow: hidden;
        justify-items: center;
        align-items: center;
        justify-content: center;
        align-content: center;
        display: grid;
    }
    .item:nth-child(4) > div:first-child {
        width: 95vw;
        height: 30vh;
        overflow: hidden;
        justify-items: center;
        align-items: center;
        align-content: start;
        display: grid;
    }
    .item > div:last-child {
        color: #212121;
        padding: 2vw;
    }
    .item > div:last-child > h3 {
        color: #4f2981;
        font-size: 3vh;
        margin-right: 2vw;
        margin-left: 2vw;
    }
    .item > div:last-child > p {
        font-size: 2vh;
        margin-left: 2vw;
        margin-right: 2vw;
    }
}
@media (min-width: 769px) and (max-width: 1024px) {
    .process {
        display: grid;
        grid-template-columns: 1fr 1fr;
        height: 100vh;
    }
    .leftProc {
        display: grid;
        justify-items: center;
        align-items: center;
    }
    .leftProc > div {
        width: 30vw;
        height: 80vh;
        display: grid;
        text-align: center;
        color: #212121;
        border-radius: 5vh !important;
        font-size: 3vh;
    }
    .leftProc > div > div:hover {
        color: #4f2981;
        background: #212121;
        cursor: pointer;
    }
    .leftProc > div > div {
        border-bottom: 2px solid #212121;
        justify-content: center;
        align-items: center;
        border-radius: 5vh;
        display: grid;
        transition: 0.5s !important;
    }
    .rightProc {
        margin: auto;
    }
    .item {
        grid-template-rows: 40vh 30vh;
        width: 40vw;
        height: 70vh;
        background: #5d5d5d;
        border-radius: 4vh;
        overflow: hidden;
        margin: auto;
        transition: 1s;
    }
    .item > div > img {
        width: 40vw;
        margin: auto;
    }
    .item > div:first-child {
        width: 40vw;
        height: 40vh;
        overflow: hidden;
        justify-items: center;
        align-items: center;
        justify-content: center;
        align-content: center;
        display: grid;
    }
    .item:nth-child(4) > div:first-child {
        width: 40vw;
        height: 40vh;
        overflow: hidden;
        justify-items: center;
        align-items: center;
        align-content: start;
        display: grid;
    }
    .item > div:last-child {
        color: #212121;
    }
    .item > div:last-child > h3 {
        color: #4f2981;
        font-size: 2vw;
        margin-right: 2vw;
        margin-left: 2vw;
    }
    .item > div:last-child > p {
        font-size: 1.2vw;
        margin-left: 2vw;
        margin-right: 2vw;
    }
}
@media (min-width: 1025px) {
    .process {
        display: grid;
        grid-template-columns: 1fr 1fr;
        height: 100vh;
    }
    .leftProc {
        display: grid;
        justify-items: center;
        align-items: center;
    }
    .leftProc > div {
        width: 30vw;
        height: 80vh;
        display: grid;
        text-align: center;
        color: #212121;
        border-radius: 5vh !important;
        font-size: 3vh;
    }
    .leftProc > div > div:hover {
        color: #4f2981;
        background: #212121;
        cursor: pointer;
    }
    .leftProc > div > div {
        border-bottom: 2px solid #212121;
        justify-content: center;
        align-items: center;
        border-radius: 5vh;
        display: grid;
        transition: 0.5s !important;
    }
    .rightProc {
        margin: auto;
    }
    .item {
        grid-template-rows: 40vh 30vh;
        width: 40vw;
        height: 70vh;
        background: #5d5d5d;
        border-radius: 4vh;
        overflow: hidden;
        margin: auto;
        transition: 1s;
    }
    .item > div > img {
        width: 40vw;
        margin: auto;
    }
    .item > div:first-child {
        width: 40vw;
        height: 40vh;
        overflow: hidden;
        justify-items: center;
        align-items: center;
        justify-content: center;
        align-content: center;
        display: grid;
    }
    .item:nth-child(4) > div:first-child {
        width: 40vw;
        height: 40vh;
        overflow: hidden;
        justify-items: center;
        align-items: center;
        align-content: start;
        display: grid;
    }
    .item > div:last-child {
        color: #212121;
    }
    .item > div:last-child > h3 {
        color: #4f2981;
        font-size: 2vw;
        margin-right: 2vw;
        margin-left: 2vw;
    }
    .item > div:last-child > p {
        font-size: 1.2vw;
        margin-left: 2vw;
        margin-right: 2vw;
    }
}
